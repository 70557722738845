import React from "react";
import "../../css/map.css";
export const EN_YEONAM = () => {
  return (
    <div className="pt-24 px-4 max-w-screen-lg">
      <h4 className="text-4xl sm:text-5xl font-bold text-center m-auto">
        Joil Co., Ltd. Yeonam Factory
      </h4>
      <div className="w-36 h-1 bg-gray-500 m-auto mt-12 mb-24"></div>
      <div className="max-w-screen-lg m-auto pb-24">
        <div className="mapRatio relative">
          <iframe
            className="w-full h-full absolute left-0 top-0"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3245.059753532928!2d129.3638820155488!3d35.576916943292716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3567cc7cb7f3e743%3A0x395b893b5dd17b34!2z7Jq47IKw6rSR7Jet7IucIOu2geq1rCDsl7DslZTrj5kgMTQwNQ!5e0!3m2!1sko!2skr!4v1636357386012!5m2!1sko!2skr"
          ></iframe>
        </div>
      </div>
      <table className="info border-t-2 border-b-2 w-full border-gray-700">
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Date established
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">2014. 01. 01.</td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Business type
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">
            Car body part production
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Factory size
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">
            Site area 66,540 sqft / Building area 37,150 sqft
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Key items
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5 uppercase">
            DR beam ASS'Y REINF{" "}
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Main customers
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">
            Hyundai Motor Company, Sungwoo Hitech, etc.
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Address
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">
            1405, Yeonam-dong, Buk-gu, Ulsan
          </td>
        </tr>
        <tr>
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Contact No
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">052-280-0900</td>
        </tr>
      </table>
    </div>
  );
};
