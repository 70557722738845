import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import SwiperCore from "swiper/core";
import "swiper/swiper.scss";
import { mainproduct } from "./homedata";
import { isForStatement } from "typescript";
import back from "../../../assets/icon/backArrow.png";
SwiperCore.use([Navigation, Pagination, Autoplay]);
export const EN_Product: React.FC = () => {
  const [index, setindex] = useState<number>(1);
  const indexHandler = (num: number) => {
    setindex(() => {
      return num;
    });
  };
  return (
    <div className="bg-main1 max-h-screen lg:h-screen overflow-hidden pt-20 lg:pt-40 pb-20 ">
      <div className="2xl:max-w-screen-xl pb-20 lg:pb-0 px-5 sm:px-20 lg:px-0 w-full h-full lg:overflow-hidden relative m-auto text-white">
        <div className="max-w-screen-lg m-auto pro-header text-white space-y-10 pt-3 pb-20 px-10">
          <p className="text-center lg:text-left font-bold text-5xl sm:text-6xl lg:text-7xl pt-0 lg:pt-20">
            Production list
            <a
              href="/business/products"
              className="capitalize lg:float-right lg:inline-flex flex m-auto mt-5 lg:m-0 flex-wrap text-2xl content-center justify-center w-60 h-16 lg:w-72 lg:h-24 border-white border text-white"
            >
              detail
            </a>
          </p>
          {/* <p className="text-center sm:text-left text-2xl sm:text-4xl pb-10">
            젊은 DON의 미래를 함께 이끌어갈 주인공은 당신입니다.
            
          </p> */}
        </div>
        <Swiper
          spaceBetween={0}
          loopAdditionalSlides={1}
          slidesPerView={1}
          speed={450}
          onSlideChange={(swiper) => {
            var slide = document
              .getElementsByClassName("prodS")[0]
              .getElementsByClassName("swiper-slide");
            for (let i = 0; i < slide.length; i++) {
              slide[i].getElementsByTagName("video")[0].currentTime = 0;
              slide[i].getElementsByTagName("video")[0].pause();
              slide[swiper.activeIndex].getElementsByTagName("video")[0].play();
            }
          }}
          loop={true}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          className={`relative prodS`}
        >
          <button className="swiper-button-prev f-button-l w-28 h-28 lg:flex flex-wrap content-center justify-center hidden bg-main2">
            <img src={back} alt="priv" />
          </button>
          <button className="swiper-button-next f-button-r w-28 h-28 lg:flex flex-wrap content-center justify-center hidden bg-main2">
            <img src={back} alt="next" className="transform rotate-180" />
          </button>
          <div className="swiper-pagination absolute -bottom-24 z-10 lg:hidden block w-full text-center"></div>

          {mainproduct.map((obj) => {
            return (
              <SwiperSlide
                className={`flex flex-wrap content-center justify-center px-10`}
                key={obj.numbering}
              >
                {obj.type == 1 && (
                  <img
                    src={obj.path}
                    alt={obj.what}
                    className="lg:max-w-screen-lg prd-image w-full"
                  />
                )}
                {obj.type == 2 && (
                  <video
                    muted
                    onEnded={() => {
                      (
                        document
                          .getElementsByClassName("prodS")[0]
                          .getElementsByClassName(
                            "swiper-button-next"
                          )[0] as HTMLButtonElement
                      ).click();
                    }}
                    src={obj.path}
                    title={obj.what}
                    className="prd-video w-full max-w-screen-sm lg:max-w-screen-lg"
                  />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
