import React from "react";
import "../../css/intro.css";
import circle1 from "../../../assets/icon/c-1.png";
import circle2 from "../../../assets/icon/c-2.png";
import circle3 from "../../../assets/icon/c-3.png";
import circle4 from "../../../assets/icon/c-4.png";
import circle5 from "../../../assets/icon/c-5.png";
import { Fade, Slide } from "react-awesome-reveal";
import shape from "../../../assets/icon/Shape.png";
import strategy from "../../../assets/images/intro/fivest.png";
export const Philosophy = () => {
  return (
    <div className="max-w-screen-xl w-full px-10 py-24 lg:py-48 m-auto">
      <div className="title_logo">
        <img src={shape} alt="손을 악수하는 모습의 아이콘" className="m-auto" />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-5xl sm:text-6xl font-bold text-center">
        <span className="leading-normal">경영철학</span>
      </h3>
      <div className="philo-main">
        <div className="stt pt-24 lg:pt-48 text-center">
          <p className="font-bold text-4xl capitalize">
            management philosophy 5+5
          </p>
          <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          <p className=" text-3xl text-gray-400">
            <span className="capitalize">a</span> leading company for the
            production of car body prats in{" "}
            <span className="capitalize">South Korea</span>
          </p>
        </div>
        <Fade duration={800} triggerOnce={true}>
          <div className="coaster lg:flex lg:flex-wrap lg:content-center lg:justify-between mt-20 py-16 lg:mt-48 lg:py-36 relative ">
            <div className="coaster-mobile lg:absolute lg:left-1/2 lg:top-1/2 transform lg:-translate-x-1/2 lg:-translate-y-1/2 flex flex-wrap flex-col content-center justify-center">
              <Fade cascade delay={1000} triggerOnce={true}>
                <p className="centertitle text-4xl lg:text-5xl font-bold text-main1 text-center">
                  고객 만족
                </p>
                <p className="mt-10 centertitle text-4xl lg:text-5xl font-bold text-main1 text-center">
                  목표 달성 경영
                </p>
              </Fade>
            </div>
            <div className="between">
              <Fade cascade delay={1000} triggerOnce={true}>
                <p className="text-4xl leading-normal font-bold text-main1 text-center ">
                  경영철학
                </p>
                <p className="mt-10 text-3xl leading-normal font-bold text-main1 text-center ">
                  <span className="capitalize">customer</span> satisfaction
                  <span className="capitalize"> no. 1</span>
                </p>
                <ul className="mt-10 text-2xl lg:list-disc space-y-3 lg:space-y-0 text-center sm:text-left">
                  <li>
                    원칙 중시:{" "}
                    <span className="block sm:inline pt-1">
                      업무를 수행함에 있어 원칙을 준수
                    </span>{" "}
                  </li>
                  <li>
                    의사 소통:
                    <span className="block sm:inline pt-1">
                      조직/구성원 ＆ 고객과의 쌍방향 소통을 통해 상호 협력관계를
                      증진
                    </span>{" "}
                  </li>
                  <li>
                    자기 개발 / 도전정신:
                    <span className="block sm:inline pt-1">
                      자기개발을 통한 끊임없는 도전을 추구
                    </span>{" "}
                  </li>
                  <li>
                    고객 중심:
                    <span className="block sm:inline pt-1">
                      고객 가치 창출에 기여함으로써 조일의 비전을 달성
                    </span>{" "}
                  </li>
                </ul>
              </Fade>
            </div>
            <div className="between">
              <Fade cascade delay={1000} triggerOnce={true}>
                <p className="text-4xl leading-normal font-bold text-main1 text-center ">
                  핵심가치실현
                </p>
                <div className="line hidden lg:block"></div>
                <p className="mt-10 text-3xl leading-normal font-bold text-main1 text-center ">
                  <span className="capitalize">the</span> core value is realized
                </p>
                <ul className="text-center lg:text-left mt-10 text-2xl lg:list-disc lg:pl-10 space-y-2 lg:space-y-0">
                  <li>원가 경쟁력 </li>
                  <li>인재 경쟁력</li>
                  <li>서비스 경쟁력</li>
                  <li>품질 경쟁력</li>
                  <li>기술 경쟁력</li>
                </ul>
              </Fade>
            </div>
          </div>
        </Fade>

        <div className="stt pt-28 lg:pt-48 text-center">
          <p className="font-bold text-4xl capitalize">
            고객만족을 달성을 위한 다섯 가지 경영철학!
          </p>
          <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          <p className=" text-3xl text-main1">
            원칙을 준수하고 상호협력을 도모하며 자기개발을 통한 발전 아래,
            끊임없는 도전 정신으로 고객 가치 창출에 기여한다.
          </p>
        </div>
        <div className="mt-24 lg:mt-48">
          <Fade
            cascade
            direction="up"
            delay={100}
            duration={800}
            triggerOnce={true}
            className="oneoffive relative "
          >
            <div className="absolute w-full h-full left-0 top-0">
              <div className="absolute bor topC rounded-t-full"></div>
              <div className="absolute bor bottomC rounded-b-full"></div>
              <div className="absolute rounded-full  left-0 top-0 w-full h-full z-10 flex flex-wrap flex-col content-center justify-center space-y-10">
                <img
                  className="m-auto my-0 w-1/5"
                  src={circle1}
                  alt="저울질 하는 아이콘"
                />
                <p className="font-bold text-5xl">원칙</p>
              </div>
            </div>
            <div className="absolute w-full h-full left-0 top-0">
              <div className="absolute bor topC rounded-t-full"></div>
              <div className="absolute bor bottomC rounded-b-full"></div>
              <div className="absolute rounded-full  left-0 top-0 w-full h-full z-10 flex flex-wrap flex-col content-center justify-center space-y-10">
                <img
                  className="m-auto my-0 w-1/5"
                  src={circle2}
                  alt="분석차트 아이콘"
                />
                <p className="font-bold text-5xl">도전</p>
              </div>
            </div>
            <div className="absolute w-full h-full left-0 top-0">
              <div className="absolute bor topC rounded-t-full"></div>
              <div className="absolute bor bottomC rounded-b-full"></div>
              <div className="absolute rounded-full  left-0 top-0 w-full h-full z-10 flex flex-wrap flex-col content-center justify-center space-y-10">
                <img
                  className="m-auto my-0 w-1/5"
                  src={circle3}
                  alt="종이에 그래프가 그려진 아이콘"
                />
                <p className="font-bold text-5xl">발전</p>
              </div>
            </div>
            <div className="absolute w-full h-full left-0 top-0">
              <div className="absolute bor topC rounded-t-full"></div>
              <div className="absolute bor bottomC rounded-b-full"></div>
              <div className="absolute rounded-full  left-0 top-0 w-full h-full z-10 flex flex-wrap flex-col content-center justify-center space-y-10">
                <img
                  className="m-auto my-0 w-1/5"
                  src={circle4}
                  alt="원 안에 사람모양의 실루엣이 있는 아이콘"
                />
                <p className="font-bold text-5xl">고객</p>
              </div>
            </div>
            <div className="absolute w-full h-full left-0 top-0">
              <div className="absolute bor topC rounded-t-full"></div>
              <div className="absolute bor bottomC rounded-b-full"></div>
              <div className="absolute rounded-full  left-0 top-0 w-full h-full z-10 flex flex-wrap flex-col content-center justify-center space-y-10">
                <img
                  className="m-auto my-0 w-1/5"
                  src={circle5}
                  alt="3명의 사람이 대화하는 아이콘"
                />
                <p className="font-bold text-5xl">소통</p>
              </div>
            </div>
          </Fade>

          <div className="stt pt-40 lg:pt-64 text-center">
            <p className="font-bold text-4xl keep-all">
              핵심가치 실현을 위한 다섯 가지 추진전략!
            </p>
            <div className="bg-gray-300 w-36 h-2 m-auto mt-12"></div>
            <div className="fivestrategy pt-32 lg:pt-48">
              <Fade triggerOnce={true} delay={400}>
                <img
                  className="w-full sm:w-2/3 m-auto"
                  src={strategy}
                  alt="5각형을 그리는 선이 한붓그리기처럼 각 모서리에 원을 그리며 색이 바뀜, 맨 위부터 시계방향 대로 회색, 노란색, 주황색, 빨간색, 검은색을 그리고, 그 원들 안에는 원가 경쟁력, 인재 경쟁력, 서비스 경쟁력, 품질 경쟁력, 기술경쟁력이 각 내용을 상징하는 아이콘과 함께 적혀있다. 5각형의 가운데는 조일공업의 로고가 그려져있다."
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
