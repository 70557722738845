import React from "react";
import divers from "../../../assets/icon/Office.png";
import document from "../../../assets/icon/Book.png";
import devel from "../../../assets/icon/Innovation.png";
import "../../css/recruit.css";
export const Announce = () => {
  return (
    <div>
      <div className="announce px-4">
        <div className="announce-grid announce-height">
          <div className="announce-rounded-left bg-gray-300 flex flex-wrap flex-col content-center justify-center">
            <div className="w-1/4 m-auto my-0">
              <img src={divers} alt="빌딩 앞에 사람들 아이콘" />
            </div>
            <p className="mt-10 font-bold text-main1 text-2xl sm:text-4xl tracking-tight">
              전형구분
            </p>
          </div>
          <div className="py-5 sm:py-0 announce-rounded-right border border-gray-400 border-l-0 flex flex-wrap flex-col content-center justify-center">
            <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal sm:leading-normal">
              <p className="font-bold tracking-tight">- 정기채용</p>
              <p className="pl-7 tracking-tight">
                신입사원 채용을 위한 전형으로 1회/년 공개채용이 진행됩니다.
              </p>
            </div>
            <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal sm:leading-normal">
              <p className="font-bold tracking-tight">- 수시채용</p>
              <p className="pl-7">
                우수인력 필요 또는 사세 확장 등의 사유 발생 시, 수시모집 공고를
                통해 인재를 모집 합니다.
              </p>
            </div>
          </div>
        </div>
        <div className="announce-grid announce-height mt-24">
          <div className="announce-rounded-left bg-gray-300 flex flex-wrap flex-col content-center justify-center">
            <div className="w-1/4 m-auto my-0">
              <img src={document} alt="책 가운데 사람그림이 있는 아이콘" />
            </div>
            <p className="mt-10 font-bold text-main1 text-2xl sm:text-4xl tracking-tight">
              제출서류
            </p>
          </div>
          <div className="py-5 sm:py-0 announce-rounded-right border border-gray-400 border-l-0 flex flex-wrap flex-col content-center justify-center">
            <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal sm:leading-normal">
              <p className="font-bold tracking-tight">- 제출서류</p>
              <p className="pl-7 tracking-tight">
                입사지원서, 졸업증명서 및 성적증명서, 주민등록초본(남자에 한함,
                군 경력 확인용) 및 등본, TOEIC 성적서 사본(소지자에 한함),
                자격증 사본(소지자에 한함) ※경력자는 경력사항 및 희망연봉을 필히
                기재하여야 하며, 제출된 서류는 반환하지 않습니다.(지원내용과
                사실이 상이할 경우 불합격 처리 됩니다.)
              </p>
            </div>
          </div>
        </div>
        <div className="announce-grid announce-height mt-24">
          <div className="announce-rounded-left bg-gray-300 flex flex-wrap flex-col content-center justify-center">
            <div className="w-1/4 m-auto my-0">
              <img src={devel} alt="사람 세명 머리 위로 전구가 떠있는 아이콘" />
            </div>
            <p className="mt-10 font-bold text-main1 text-2xl sm:text-4xl tracking-tight">
              지원방법
            </p>
          </div>
          <div className="py-5 sm:py-0 announce-rounded-right border border-gray-400 border-l-0 flex flex-wrap flex-col content-center justify-center">
            <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal sm:leading-normal">
              <p className="font-bold tracking-tight">- 지원방법</p>
              <p className="pl-7 tracking-tight">
                입사지원서(자율양식) 작성 후,{" "}
                <span className="text-logo">joil_01@joilltd.co.kr</span> 또는
                울산광역시 북구 효성로 43(효문동) 조일공업㈜ 모집 분야별
                채용담당자(모집공고문 확인) 앞으로 우편발송하시면 됩니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
