import React from "react";
import divers from "../../../assets/icon/Office.png";
import document from "../../../assets/icon/Book.png";
import devel from "../../../assets/icon/Innovation.png";
import "../../css/recruit.css";
export const EN_Announce = () => {
  return (
    <div>
      <div className="announce px-4">
        <div className="announce-grid announce-height">
          <div className="announce-rounded-left bg-gray-300 flex flex-wrap flex-col content-center justify-center">
            <div className="w-1/4 m-auto my-0">
              <img src={divers} alt="peple front of building" />
            </div>
            <p className="px-2 text-center mt-10 font-bold text-main1 text-xl sm:text-3xl">
              Type
              <br /> classification
            </p>
          </div>
          <div className="py-2 sm:py-0 announce-rounded-right border border-gray-400 border-l-0 flex flex-wrap flex-col content-center justify-center">
            <div className="text-xl sm:text-3xl w-full px-5 md:px-32 leading-normal">
              <p className="font-bold">- Regular recruitment</p>
              <p className="pl-1 sm:pl-7 break-all">
                1 open recruitment/ year to hire new employees.
              </p>
            </div>
            <div className="text-xl sm:text-3xl w-full px-5 md:px-32 leading-normal">
              <p className="font-bold">- On-demand recruitment</p>
              <p className="pl-1 sm:pl-7 break-all">
                If excellent employees are required or an expansion occurs,
                etc., employees are recruited through an on-demand recruitment
                announcement.
              </p>
            </div>
          </div>
        </div>
        <div className="announce-grid announce-height mt-24">
          <div className="announce-rounded-left bg-gray-300 flex flex-wrap flex-col content-center justify-center">
            <div className="w-1/4 m-auto my-0">
              <img
                src={document}
                alt="a book icon there is a person center of book cover"
              />
            </div>
            <p className="px-2 text-center mt-10 font-bold text-main1 text-xl sm:text-3xl">
              Documents to submit
            </p>
          </div>
          <div className="py-2 sm:py-0 announce-rounded-right border border-gray-400 border-l-0 flex flex-wrap flex-col content-center justify-center">
            <div className="text-xl sm:text-3xl w-full px-5 md:px-32 leading-normal">
              <p className="font-bold">- Documents to submit</p>
              <p className="pl-1 sm:pl-7 break-all">
                Job application, graduation certificate and transcript, resident
                registration abstract (for men, to check military status) and
                copy, TOEIC test report (when applicable), copy of license (when
                applicable) ※Experienced employees must enter career details and
                the desired salary, and the submitted documents are not
                returned. (you will be rejected if the application details do
                not match the documents.)
              </p>
            </div>
          </div>
        </div>
        <div className="announce-grid announce-height mt-24">
          <div className="announce-rounded-left bg-gray-300 flex flex-wrap flex-col content-center justify-center">
            <div className="w-1/4 m-auto my-0">
              <img src={devel} alt="a bulb above three people" />
            </div>
            <p className="text-center mt-10 font-bold text-main1 text-xl sm:text-3xl">
              Submit
            </p>
          </div>
          <div className="py-2 sm:py-0 announce-rounded-right border border-gray-400 border-l-0 flex flex-wrap flex-col content-center justify-center">
            <div className="text-xl sm:text-3xl w-full px-5 md:px-32 leading-normal">
              <p className="font-bold">- Submit</p>
              <p className="pl-1 sm:pl-7 break-all">
                after write up job application, mail to{" "}
                <span className="text-logo">joil_01@joilltd.co.kr</span> or send
                to Recruiter in 43, Hyoseong-ro, Buk-gu, Ulsan (Hyomun-dong)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
