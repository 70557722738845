import React from "react";
import { FOOTER } from "../../../layout/eng/footer";
import { SUBHEADER } from "../../../layout/eng/subheader";
import { EN_Philosophy } from "../../../parts/eng/intro/philosophy";
import { Helmet } from "react-helmet";
class EN_PHILOSOPHY extends React.Component {
  render() {
    return (
      <main id="content">
        <SUBHEADER />
        <div id="intro">
          <EN_Philosophy />
        </div>
        <FOOTER />
      </main>
    );
  }
}
export default EN_PHILOSOPHY;
