import React from "react";
import { Fade } from "react-awesome-reveal";
import resource from "../../../assets/icon/HumanResoure.png";
import success from "../../../assets/icon/Success.png";
export const Benefit = () => {
  return (
    <div className="pt-24 px-4">
      <h4 className="text-4xl sm:text-5xl font-bold text-center">보상정책</h4>
      <div className="w-36 h-1 bg-gray-500 m-auto mt-12 mb-24"></div>
      <div className="benefit pt-10 sm:pt-24">
        <Fade triggerOnce={true}>
          <div className="benefit-height recruit-benefit-grid">
            <div className="bg-main1 flex flex-wrap flex-col content-center justify-center">
              <img
                src={resource}
                alt="보상제도 아이콘 양손 위에 사람이 올라가있음."
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="text-white font-bold text-2xl sm:text-4xl mt-20">
                보상제도
              </p>
            </div>
            <div className="py-5 text-main1 border border-gray-500 flex flex-wrap flex-col content-center justify-center">
              <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal tracking-tight">
                <p className="font-bold">- 기본연봉</p>
                <p className="pl-6">
                  기본급 + 상여금(고과에 따라 차등 인상률 적용)
                </p>
              </div>
              <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal tracking-tight">
                <p className="font-bold">- 업적 성과급</p>
                <p className="pl-6">
                  역량 및 업적평가에 따라 차등 지급(연봉제)
                </p>
              </div>
              <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal tracking-tight">
                <p className="font-bold">- 우수사원 포상</p>
                <p className="pl-6">
                  연간 각 분야별 우수사원 선정 후 성과에 따른 포상금 지급
                </p>
              </div>
              <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal tracking-tight">
                <p className="font-bold">- 소속별 근무성과 우수포상</p>
                <p className="pl-6">
                  1회/월 근무성과가 우수한 반(팀)을 선정해 포상금 지급
                </p>
              </div>
            </div>
          </div>
          <div className="mt-24 sm:mt-48 benefit-height recruit-benefit-grid">
            <div className="bg-main1 flex flex-wrap flex-col content-center justify-center">
              <img
                src={success}
                alt="보상제도 아이콘 양손 위에 사람이 올라가있음."
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="text-white font-bold text-2xl sm:text-4xl mt-20">
                복리후생
              </p>
            </div>
            <div className="text-main1 border border-gray-500 flex flex-wrap flex-col content-center justify-center">
              <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal tracking-tight">
                <p className="font-bold">- 기타 복리후생</p>
                <ul className="pl-10 sm:pl-20">
                  <li className="list-disc">
                    자녀 학자금 지원(유치원, 초·중·고, 대학)
                  </li>
                  <li className="list-disc">건강 검진 및 의료비 지원</li>
                  <li className="list-disc">
                    경조휴가 및 경조금, 경조 물품 지원
                  </li>
                  <li className="list-disc">
                    외국인 및 장거리 근로자 사택 제공
                  </li>
                  <li className="list-disc">주택구매 무이자 대출</li>
                  <li className="list-disc">체육대회 및 야유회 개최</li>
                  <li className="list-disc">사내 동호회 지원</li>
                  <li className="list-disc">명절 및 생일 선물 지급</li>
                  <li className="list-disc">
                    장애인 고용 외 사내규정에 의한 복리지원
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};
