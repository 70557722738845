import React from "react";
import "../../css/intro.css";
import circle1 from "../../../assets/icon/c-1.png";
import circle2 from "../../../assets/icon/c-2.png";
import circle3 from "../../../assets/icon/c-3.png";
import circle4 from "../../../assets/icon/c-4.png";
import circle5 from "../../../assets/icon/c-5.png";
import { Fade, Slide } from "react-awesome-reveal";
import shape from "../../../assets/icon/Shape.png";
import strategy from "../../../assets/images/intro/en_fivest.png";
export const EN_Philosophy = () => {
  return (
    <div className="max-w-screen-xl w-full px-10 py-24 lg:py-48 m-auto">
      <div className="title_logo">
        <img src={shape} alt="icon of shaking hands" className="m-auto" />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-5xl sm:text-6xl font-bold text-center">
        <span className="leading-normal">Management philosophy</span>
      </h3>
      <div className="philo-main">
        <div className="stt pt-24 lg:pt-48 text-center">
          <p className="font-bold text-4xl capitalize">
            management philosophy 5+5
          </p>
          <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          <p className=" text-3xl text-gray-400">
            <span className="capitalize">a</span> leading company for the
            production of car body prats in{" "}
            <span className="capitalize">South Korea</span>
          </p>
        </div>
        <Fade duration={800} triggerOnce={true}>
          <div className="coaster lg:flex lg:flex-wrap lg:content-center lg:justify-between mt-20 py-16 lg:mt-48 lg:py-36 relative ">
            <div className="coaster-mobile lg:absolute lg:left-1/2 lg:top-1/2 transform lg:-translate-x-1/2 lg:-translate-y-1/2 flex flex-wrap flex-col content-center justify-center">
              <Fade cascade delay={1000} triggerOnce={true}>
                <p className="centertitle text-4xl lg:text-5xl font-bold text-main1 text-center">
                  Customer satisfaction
                </p>
                <p className="mt-10 centertitle text-4xl lg:text-5xl font-bold text-main1 text-center">
                  Goal achievement
                </p>
              </Fade>
            </div>
            <div className="between">
              <Fade cascade delay={1000} triggerOnce={true}>
                <p className="text-4xl leading-normal font-bold text-main1 text-center ">
                  Managment philosophy
                </p>
                <p className="mt-5 text-3xl leading-normal font-bold text-main1 text-center ">
                  <span className="capitalize">customer</span> satisfaction
                  <span className="capitalize"> no. 1</span>
                </p>
                <ul className="mt-5 text-2xl lg:list-disc space-y-3 lg:space-y-0 text-center sm:text-left">
                  <li>
                    Emphasize principle:{" "}
                    <span className="block sm:inline pt-1">
                      Follow the principle in performing tasks
                    </span>{" "}
                  </li>
                  <li>
                    Communication:
                    <span className="block sm:inline pt-1">
                      Enhance mutual cooperation with bidirectional
                      communication between the organization/members ＆ clients
                    </span>{" "}
                  </li>
                  <li>
                    Self-development / challenging mindset:
                    <span className="block sm:inline pt-1">
                      Seek endless challenges through self-development
                    </span>{" "}
                  </li>
                  <li>
                    Customer-centered:
                    <span className="block sm:inline pt-1">
                      Contribute to creating client value to achieve Joil’s
                      vision
                    </span>{" "}
                  </li>
                </ul>
              </Fade>
            </div>
            <div className="between">
              <Fade cascade delay={1000} triggerOnce={true}>
                <p className="text-4xl leading-normal font-bold text-main1 text-center ">
                  Realize key values
                </p>
                <div className="line hidden lg:block"></div>
                <p className="mt-10 text-3xl leading-normal font-bold text-main1 text-center ">
                  <span className="capitalize">the</span> core value is realized
                </p>
                <ul className="text-center lg:text-left mt-10 text-2xl lg:list-disc lg:pl-10 space-y-2 lg:space-y-0">
                  <li>Production cost competitiveness </li>
                  <li>Talent competitiveness</li>
                  <li>Service competitiveness</li>
                  <li>Quality competitiveness</li>
                  <li>Technology competitiveness</li>
                </ul>
              </Fade>
            </div>
          </div>
        </Fade>

        <div className="stt pt-28 lg:pt-48 text-center">
          <p className="font-bold text-4xl capitalize">
            Five management philosophies to achieve customer satisfaction!
          </p>
          <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          <p className=" text-3xl text-main1">
            Follow principles, seek mutual cooperation, and develop through
            self-development while contributing to creating customer value with
            an endless challenging mindset.
          </p>
        </div>
        <div className="mt-24 lg:mt-48">
          <Fade
            cascade
            direction="up"
            delay={100}
            duration={800}
            triggerOnce={true}
            className="oneoffive relative "
          >
            <div className="absolute w-full h-full left-0 top-0">
              <div className="absolute bor topC rounded-t-full"></div>
              <div className="absolute bor bottomC rounded-b-full"></div>
              <div className="absolute rounded-full  left-0 top-0 w-full h-full z-10 flex flex-wrap flex-col content-center justify-center space-y-10">
                <img
                  className="m-auto my-0 w-1/5"
                  src={circle1}
                  alt="저울질 하는 아이콘"
                />
                <p className="font-bold text-5xl">Principle </p>
              </div>
            </div>
            <div className="absolute w-full h-full left-0 top-0">
              <div className="absolute bor topC rounded-t-full"></div>
              <div className="absolute bor bottomC rounded-b-full"></div>
              <div className="absolute rounded-full  left-0 top-0 w-full h-full z-10 flex flex-wrap flex-col content-center justify-center space-y-10">
                <img
                  className="m-auto my-0 w-1/5"
                  src={circle2}
                  alt="분석차트 아이콘"
                />
                <p className="font-bold text-5xl">Challenge </p>
              </div>
            </div>
            <div className="absolute w-full h-full left-0 top-0">
              <div className="absolute bor topC rounded-t-full"></div>
              <div className="absolute bor bottomC rounded-b-full"></div>
              <div className="absolute rounded-full  left-0 top-0 w-full h-full z-10 flex flex-wrap flex-col content-center justify-center space-y-10">
                <img
                  className="m-auto my-0 w-1/5"
                  src={circle3}
                  alt="종이에 그래프가 그려진 아이콘"
                />
                <p className="font-bold text-5xl">Development </p>
              </div>
            </div>
            <div className="absolute w-full h-full left-0 top-0">
              <div className="absolute bor topC rounded-t-full"></div>
              <div className="absolute bor bottomC rounded-b-full"></div>
              <div className="absolute rounded-full  left-0 top-0 w-full h-full z-10 flex flex-wrap flex-col content-center justify-center space-y-10">
                <img
                  className="m-auto my-0 w-1/5"
                  src={circle4}
                  alt="원 안에 사람모양의 실루엣이 있는 아이콘"
                />
                <p className="font-bold text-5xl">Customer</p>
              </div>
            </div>
            <div className="absolute w-full h-full left-0 top-0">
              <div className="absolute bor topC rounded-t-full"></div>
              <div className="absolute bor bottomC rounded-b-full"></div>
              <div className="absolute rounded-full  left-0 top-0 w-full h-full z-10 flex flex-wrap flex-col content-center justify-center space-y-10">
                <img
                  className="m-auto my-0 w-1/5"
                  src={circle5}
                  alt="3명의 사람이 대화하는 아이콘"
                />
                <p className="font-bold text-5xl">Communication</p>
              </div>
            </div>
          </Fade>

          <div className="stt pt-40 lg:pt-64 text-center">
            <p className="font-bold text-4xl keep-all">
              Five management philosophies to achieve customer satisfaction!
            </p>
            <div className="bg-gray-300 w-36 h-2 m-auto mt-12"></div>
            <div className="fivestrategy pt-32 lg:pt-48">
              <Fade triggerOnce={true} delay={400}>
                <img
                  className="w-full sm:w-2/3 m-auto"
                  src={strategy}
                  alt="imgae of pentagon and in pentagon, there are five values cost competitiveness, Talent competitiveness, Service competitiveness, Quality competitiveness, Technology competitiveness on each edge position."
                />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
