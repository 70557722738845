import React from "react";
import light from "../../../assets/icon/blight.png";
import eff from "../../../assets/icon/effi.png";
import qual from "../../../assets/icon/qual.png";
import chall from "../../../assets/icon/chall.png";
import vision1 from "../../../assets/icon/vision1.png";
import vision2 from "../../../assets/icon/vision2.png";
import shape from "../../../assets/icon/Shape.png";
import { Fade } from "react-awesome-reveal";
import "../../css/intro.css";
export const Ideology = () => {
  return (
    <div className="max-w-screen-lg w-full pb-32 pt-20 md:pt-48 m-auto overflow-hidden">
      <div className="title_logo">
        <img src={shape} alt="손을 악수하는 모습의 아이콘" className="m-auto" />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-5xl sm:text-6xl font-bold text-center">
        <span className="leading-normal">경영이념</span>
      </h3>
      <div className="mt-24 md:mt-48 innovation">
        <Fade triggerOnce={true}>
          <div className="boundary lg:m-0 m-auto">
            <div className="innerCenter">
              <div className="innercontent flex flex-col flex-wrap content-center justify-center gap-y-10">
                <img
                  src={light}
                  alt="뇌와 전구를 합쳐논 그림"
                  className="w-1/4 m-auto my-0"
                />
                <p className="text-center font-bold text-logo text-3xl md:text-5xl">
                  가치혁신 추구
                </p>
                <p className="text-center font-bold text-logo capitalize text-3xl md:text-5xl">
                  value innovation
                </p>
              </div>
            </div>
            <Fade
              delay={200}
              direction="left"
              className="lg:absolute satellite"
              triggerOnce={true}
            >
              <div className="max-w-xl m-auto relative">
                <div className="absolute left-0 top-0 bg-logo rounded-full sm:w-48 sm:h-48 w-32 h-32 flex flex-wrap content-center justify-center">
                  <img
                    src={eff}
                    alt="로봇팔로 물건을 집는 이미지"
                    className="w-1/2"
                  />
                </div>
                <div className=" sm:w-330 sm:h-48 h-32">
                  <div className="h-16 sm:h-24 w-full border border-t-0 border-l-0 border-r-0 border-dashed border-gray-400 pr-5 ">
                    <div className="w-full h-full flex flex-wrap justify-end content-end">
                      <span className="text-3xl sm:text-4xl text-logo capitalize leading-normal">
                        high efficiency
                      </span>
                    </div>
                  </div>
                  <div className="h-24 w-full flex flex-wrap justify-end pt-0 sm:pt-8">
                    <div className="w-1/2 h-full flex flex-wrap justify-end content-center">
                      <span className="text-xl sm:text-3xl text-gray-400 keep-all">
                        고효율·고품질 제품 생산을 위한 검증된 기술력
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade
              delay={400}
              direction="left"
              className="lg:absolute satellite"
              triggerOnce={true}
            >
              <div className="max-w-xl m-auto relative">
                <div className="absolute left-0 top-0 bg-logo rounded-full sm:w-48 sm:h-48 w-32 h-32 flex flex-wrap content-center justify-center">
                  <img
                    src={qual}
                    alt="로봇팔로 세로로 되어있는 선반 물건을 집음"
                    className="w-1/2"
                  />
                </div>
                <div className=" sm:w-330 sm:h-48 h-32">
                  <div className="h-16 sm:h-24 w-full border border-t-0 border-l-0 border-r-0 border-dashed border-gray-400 pr-5 ">
                    <div className="w-full h-full flex flex-wrap justify-end content-end">
                      <span className="text-3xl sm:text-4xl text-logo capitalize leading-normal">
                        high quality
                      </span>
                    </div>
                  </div>
                  <div className="h-24 w-full flex flex-wrap justify-end pt-0 sm:pt-8">
                    <div className="w-1/2 h-full flex flex-wrap justify-end content-center">
                      <span className="text-xl sm:text-3xl text-gray-400 keep-all">
                        체계적인 설계와 공정성이 집약된 품질력
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade
              delay={600}
              direction="left"
              className="lg:absolute satellite"
              triggerOnce={true}
            >
              <div className="max-w-xl m-auto relative">
                <div className="absolute left-0 top-0 bg-logo rounded-full sm:w-48 sm:h-48 w-32 h-32 flex flex-wrap content-center justify-center">
                  <img
                    src={chall}
                    alt="세개의 손을 한곳으로 모으는 아이콘"
                    className="w-1/2"
                  />
                </div>
                <div className=" sm:w-330 sm:h-48 h-32">
                  <div className="h-16 sm:h-24 w-full border border-t-0 border-l-0 border-r-0 border-dashed border-gray-400 pr-5 ">
                    <div className="w-full h-full flex flex-wrap justify-end content-end">
                      <span className="text-3xl sm:text-4xl text-logo capitalize leading-normal">
                        challenge
                      </span>
                    </div>
                  </div>
                  <div className="h-24 w-full flex flex-wrap justify-end pt-0 sm:pt-8">
                    <div className="w-1/2 h-full flex flex-wrap justify-end content-center">
                      <span className="text-xl sm:text-3xl text-gray-400 keep-all">
                        고객의 신뢰와 믿음이 만들어낸 기업경쟁력
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </Fade>
      </div>
      <div className="vision mt-32 lg:mt-64 p-10">
        <h3 className="text-6xl font-bold text-center">
          <span className="capitalize">vision</span>
        </h3>
        <div className="w-full mt-20 md:mt-60 flex flex-wrap flex-col lg:flex-row lg:justify-between relative mb-10 md:mb-72">
          <Fade
            direction="up"
            delay={100}
            triggerOnce={true}
            className="visioncircle rounded-full relative"
          >
            <div>
              <svg
                viewBox="0 0 352 352"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute apth left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-90 lg:rotate-0"
              >
                <path
                  d="M339.696 237.875C324.253 278.73 294.092 312.333 255.137 332.084L256.042 333.868C295.442 313.892 325.948 279.904 341.567 238.583L339.696 237.875ZM255.137 332.084C216.182 351.835 171.25 356.305 129.167 344.617L128.632 346.544C171.196 358.366 216.642 353.844 256.042 333.868L255.137 332.084ZM129.167 344.617C87.0847 332.928 50.8937 305.926 27.7043 268.915L26.0095 269.977C49.4639 307.411 86.0685 334.722 128.632 346.544L129.167 344.617ZM27.7043 268.915C4.51493 231.904 -3.99659 187.559 3.84191 144.593L1.87438 144.234C-6.0537 187.691 2.5551 232.543 26.0095 269.977L27.7043 268.915ZM3.84191 144.593C11.6804 101.626 35.3023 63.1437 70.067 36.7053L68.8563 35.1134C33.6943 61.8539 9.80246 100.776 1.87438 144.234L3.84191 144.593ZM70.067 36.7053C104.832 10.2669 148.226 -2.21625 191.725 1.70828L191.905 -0.283628C147.909 -4.25301 104.018 8.37282 68.8563 35.1134L70.067 36.7053ZM191.725 1.70828C235.224 5.63281 275.683 25.6813 305.155 57.9151L306.631 56.5656C276.823 23.9634 235.901 3.68576 191.905 -0.283628L191.725 1.70828ZM305.155 57.9151C334.626 90.1489 350.978 132.238 351 175.914L353 175.913C352.978 131.738 336.439 89.1678 306.631 56.5656L305.155 57.9151Z"
                  fill="#E5E5E5"
                  mask="url(#path-1-inside-1_603_3636)"
                />
              </svg>
              <div className="absolute left-0 top-0 w-full h-full flex flex-wrap flex-col justify-center space-y-8">
                <img
                  src={vision1}
                  alt="공사장모자를 쓴 사람의 아이콘"
                  className="w-1/4 m-auto my-0"
                />
                <p className="text-center font-bold text-main1 text-3xl">
                  인재경영
                </p>
                <p className="text-center text-main1 capitalize text-2xl m-auto my-0 w-3/4">
                  인간중심 경영으로 인재 발굴 및 인재 존중 기업문화를 만들어
                  나갈 것입니다.
                </p>
              </div>
            </div>
          </Fade>

          <Fade
            delay={1000}
            className="itr_confade1 lg:absolute"
            triggerOnce={true}
          >
            <div className="connect1"></div>
          </Fade>
          <Fade
            direction="up"
            delay={300}
            className="visioncircle rounded-full relative"
            triggerOnce={true}
          >
            <div>
              <svg
                viewBox="0 0 341 177"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="apth apthup absolute transform rotate-90 lg:rotate-0"
              >
                <path
                  d="M1.79963 112.641C16.5921 74.5817 44.2212 42.891 79.9066 23.0519L78.9348 21.3039C42.8417 41.3697 14.897 73.4224 -0.0645203 111.917L1.79963 112.641ZM79.9066 23.0519C115.592 3.21286 157.089 -3.52651 197.217 3.99984L197.586 2.03412C156.999 -5.57824 115.028 1.23814 78.9348 21.3039L79.9066 23.0519ZM197.217 3.99984C237.346 11.5262 273.582 32.8447 299.655 64.2671L301.194 62.99C274.823 31.2086 238.173 9.64647 197.586 2.03412L197.217 3.99984ZM299.655 64.2671C325.729 95.6896 340 135.239 340 176.073L342 176.073C342 134.773 327.566 94.7714 301.194 62.99L299.655 64.2671Z"
                  fill="#E5E5E5"
                  mask="url(#path-1-inside-1_603_3622)"
                />
              </svg>
              <svg
                viewBox="0 0 345 176"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="apth apthdown absolute transform rotate-90 lg:rotate-0"
              >
                <path
                  d="M343.134 52.121C330.661 92.0631 304.296 126.216 268.815 148.392L269.875 150.088C305.761 127.659 332.427 93.1155 345.043 52.7172L343.134 52.121ZM268.815 148.392C233.335 170.568 191.086 179.301 149.723 173.009L149.423 174.986C191.258 181.35 233.99 172.518 269.875 150.088L268.815 148.392ZM149.723 173.009C108.36 166.716 70.6186 145.814 43.336 114.09L41.8196 115.394C69.4139 147.481 107.587 168.622 149.423 174.986L149.723 173.009ZM43.336 114.09C16.0533 82.3655 1.03437 41.9169 1 0.0720546L-1 0.0736973C-0.965237 42.3965 14.2253 83.3071 41.8196 115.394L43.336 114.09Z"
                  fill="#E5E5E5"
                  mask="url(#path-1-inside-1_603_3621)"
                />
              </svg>
              <div className="absolute left-0 top-0 w-full h-full flex flex-wrap flex-col justify-center space-y-8">
                <img
                  src={vision2}
                  alt="무언가 적힌 종이를 집어놓은 보드판 아이콘"
                  className="w-1/4 m-auto my-0"
                />
                <p className="text-center font-bold text-main1 text-3xl">
                  책임경영
                </p>
                <p className="text-center text-main1 capitalize text-2xl m-auto my-0 w-3/4">
                  투명하고 정직한 경영으로 기업의 사회적·윤리적 책임을 다해 나갈
                  것입니다.
                </p>
              </div>
            </div>
          </Fade>
          <Fade
            delay={1000}
            className="itr_confade2 lg:absolute"
            triggerOnce={true}
          >
            <div className="connect2"></div>
          </Fade>
          <Fade
            direction="up"
            delay={500}
            triggerOnce={true}
            className="visioncircle rounded-full relative"
          >
            <div>
              <svg
                viewBox="0 0 352 352"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute apth -rotate-90 lg:rotate-180 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 "
              >
                <path
                  d="M339.696 237.875C324.253 278.73 294.092 312.333 255.137 332.084L256.042 333.868C295.442 313.892 325.948 279.904 341.567 238.583L339.696 237.875ZM255.137 332.084C216.182 351.835 171.25 356.305 129.167 344.617L128.632 346.544C171.196 358.366 216.642 353.844 256.042 333.868L255.137 332.084ZM129.167 344.617C87.0847 332.928 50.8937 305.926 27.7043 268.915L26.0095 269.977C49.4639 307.411 86.0685 334.722 128.632 346.544L129.167 344.617ZM27.7043 268.915C4.51493 231.904 -3.99659 187.559 3.84191 144.593L1.87438 144.234C-6.0537 187.691 2.5551 232.543 26.0095 269.977L27.7043 268.915ZM3.84191 144.593C11.6804 101.626 35.3023 63.1437 70.067 36.7053L68.8563 35.1134C33.6943 61.8539 9.80246 100.776 1.87438 144.234L3.84191 144.593ZM70.067 36.7053C104.832 10.2669 148.226 -2.21625 191.725 1.70828L191.905 -0.283628C147.909 -4.25301 104.018 8.37282 68.8563 35.1134L70.067 36.7053ZM191.725 1.70828C235.224 5.63281 275.683 25.6813 305.155 57.9151L306.631 56.5656C276.823 23.9634 235.901 3.68576 191.905 -0.283628L191.725 1.70828ZM305.155 57.9151C334.626 90.1489 350.978 132.238 351 175.914L353 175.913C352.978 131.738 336.439 89.1678 306.631 56.5656L305.155 57.9151Z"
                  fill="#E5E5E5"
                  mask="url(#path-1-inside-1_603_3636)"
                />
              </svg>
              <div className="absolute left-0 top-0 w-full h-full flex flex-wrap flex-col justify-center space-y-8">
                <img
                  src={vision1}
                  alt="공사장모자를 쓴 사람의 아이콘"
                  className="w-1/4 m-auto my-0"
                />
                <p className="text-center font-bold text-main1 text-3xl">
                  기술경영
                </p>
                <p className="text-center text-main1 capitalize text-2xl m-auto my-0 w-3/4">
                  고객감동 실현을 위한 제품·기술력 개발에 투자와 지원을 아끼지
                  않을 것입니다.
                </p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};
