import inner1 from "../assets/images/business/inner1.jpg";
import inner2 from "../assets/images/business/inner2.jpg";
import inner3 from "../assets/images/business/inner3.jpg";

export interface IManagementProps {
  subject: string;
  subject_en: string;
  url: string;
  alt: string;
  text: string;
  text_en: string;
}

export const listManagement: IManagementProps[] = [
  {
    subject: "지속가능경영",
    subject_en: "Sustainable management",
    url: inner1,
    alt: "조일공업 전경 이미지 벽면",
    text: `조일공업㈜은 고객의 신뢰와 믿음에 보답하고, 기업의 사회적·윤리적 책임을 다하기 위해 환경(Environment)과 사회(Social), 지배 구조(Governance) 중심의 ESG 경영을 실천하고 있습니다. 윤리의식을 존중하는 투명한 경영으로 지역 및 국가에 공헌하고, 지속 가능한 미래를 위한 환경문제에 기여하며, 기업 가치 상승 및 기업 경쟁력 확보를 지속적으로 추구해나갈 것입니다.`,
    text_en: `To repay the faith and trust of the customers and to fulfill the
    company’s social/moral responsibilities, Joil Co., Ltd. is practicing ESG management focused on Environment, Social, Governance. We will contribute
    locally and nationally with transparent management respecting ethics, contribute to environmental problems for a sustainable future, and continue to pursue company competitiveness and increase in company value.`,
  },
  {
    subject: "안전경영활동",
    subject_en: "Safety management activity",
    url: inner2,
    alt: "조일공업 전경 이미지 ㄷ자 모양 차가 주차되어있고, 박스가 쌓여있다.",
    text: `사내 임직원 비롯해 협력업체 및 고객의 안전은 어느 무엇보다 중요하며 절대적입니다. 글로벌 차체 부품 생산 기업으로 거듭나기 위한 최우선 과제로, 체계적인 안전보건 시스템을 구축하고 있으며, 사업장 내 안전 문화 장착 및 안전을 통한 고객감동 실현을 위해 최선의 노력을 다하고 있습니다.
    
    
    이를 위해 IATF 자동차산업 품질경영시스템, 안전보건경영시스템(ISO54001), 품질경영시스템(ISO9001) 등 다양한 안전인증을 통해 임직원들의 안전한 작업환경을 보장하고, 고객으로 하여금 안심하고 사용할 수 있는 제품을 만들어내기 위해 만전을 기하고 있습니다.`,
    text_en: `The safety of our employees, partners, and customers is most important and absolute. As the highest priority task to become a global automobile body part production company, we are establishing a systematic safety/health system and putting our best efforts to realize customer satisfaction through safety and establish a trend emphasized on safety in the workplace.
    
    
    To achieve this, we have assured a secure working environment for the employees through various safety certifications such as IATF car industry quality management system, safety/health management system (ISO54001), quality management system (ISO9001), etc. and are making every effort to develop products that the customers can trust and use.`,
  },
  {
    subject: "환경경영활동",
    subject_en: "Environment management activity",
    url: inner3,
    alt: "공장 내부 전경",
    text: `인류의 지속가능한 미래를 위해 지구와 환경을 지켜나가기 위한 노력은 선택이 아닌 필수의 시대로 접어들었습니다. 글로벌 모빌리티 산업 또한 이러한 서스테이너블(Sustainable) 트렌드에 적극 동참하며, 전기차·수소차 등 친환경 모빌리티 사회로 전환되는 새로운 패러다임과 마주하고 있습니다.
    
    
    변화에 능동적으로 대처할 수 있는 역량을 키워온 조일공업㈜은 친환경 ISO 기업 인증을 비롯해 국제환경규격(ISO14000) 등의 각종 인증을 바탕으로, 친환경 모빌리티에 접목이 가능한 친환경 소재 개발에 매진하고 있습니다.`,
    text_en: `We have reached a point where efforts to protect the Earth and the environment for mankind’s sustainable future is not a choice, but a requirement. The global mobility industry is also actively joining such sustainable trends, and we are facing a new paradigm of eco-friendly mobility society including electric cars/hydrogen cars, etc.
    
    
    Joil Co., Ltd. has grown the capability to actively respond to change and are concentrating on developing eco-friendly materials that can be used for eco-friendly mobility based on various certifications including eco-friendly ISO company certification, Environmental Management Standards (ISO14000), etc.`,
  },
];
