import React from "react";
import shape from "../../../assets/icon/Shape.png";
import sign from "../../../assets/images/intro/sign.png";
export const Comm = () => {
  return (
    <div className="max-w-screen-lg w-full px-10 pb-32 pt-20 md:pt-48 m-auto">
      <div className="title_logo">
        <img src={shape} alt="손을 악수하는 모습의 아이콘" className="m-auto" />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-5xl sm:text-6xl font-bold text-center">
        <span className="leading-normal">인사말</span>
      </h3>
      <div className="stt pt-20 md:pt-48 text-center">
        <p className="font-bold text-3xl sm:text-4xl">
          Past, Now... and Sustainable Future!
        </p>
        <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
        <p className="font-bold text-3xl sm:text-4xl">
          조일공업㈜ 홈페이지 방문을 진심으로 환영합니다.
        </p>
      </div>
      <div className="relative mainText mt-28 md:mt-56 space-y-12 text-2xl sm:text-3xl pb-48 leading-normal sm:leading-normal">
        <p>안녕하십니까.</p>
        <p>조일공업㈜ 대표이사 김수열입니다.</p>
        <p>
          지난 1997년 설립된 차체부품 생산 전문 기업 조일공업㈜은 '자동차
          차체부품 생산 일등기업'이라는 확고한 목표 달성을 위해 창의적 사고와
          뜨거운 열정, 한발 앞서 미래를 내다보는 도전정신을 실천하여 지속적인
          성장과 발전을 이어왔습니다.
        </p>
        <p>
          책임 경영, 인재경영, 기술경영의 세 가지 경영이념을 바탕으로, 자동차의
          뼈대를 이루는 차체를 비롯해 샤시와 의장, 시트 프레임 등에 이르는
          고품질·고효율의 제품을 국내외 주요 완성차 시장에 공급하며 대한민국
          자동차 산업 발전에 기여해왔습니다.
        </p>
        <p>
          다년간의 축적된 기술 노하우와{" "}
          <span className="uppercase">progressive</span>＆
          <span className="uppercase">transfer</span> 금형 설계 및 제작 능력에
          따른 원가 경쟁력 확보, 엄격한 품질보증 체계를 거쳐 생산되는 최고
          수준의 조일공업㈜ 제품은 고객감동 실현을 위한 당사의 노력이자
          약속입니다.
        </p>
        <p>
          2005년 9월 생산정보화 우수기업으로 선정된 이래, 2006년 10월 경영혁신형
          중소기업 인증을 비롯해 2016년 한국 산업단지공단이 지정한 글로벌
          선도기업으로 선정되어 명예의 전당에 이름을 올리기도 했습니다. 2018년
          울산 스타기업에도 선정되며 성장 가능성을 인정받은 조일공업㈜은 현재
          미포국가산업단지 내 두 곳의 공장을 운영하고 있으며, 2008년 충남 예산에
          자회사 ㈜조일의 설립과 함께 예산공장을 증축하여 생산라인을
          확대해나가고 있습니다.
        </p>
        <p>
          이를 기반으로 국내 굴지의 글로벌 자동차 기업 현대·기아차 1차 협력사 중
          최초로 로봇 생산 자동화 라인 구축에 성공했으며, 영남지역 최초의 금형
          자동화 적재 창고를 보유하는 등 대한민국 자체 부품 생산 트렌드를
          선도해나가고 있습니다.
        </p>
        <p>
          본격적인 4차 산업혁명 시대를 맞아 자동차는 단순한 이동 수단의 개념을
          넘어 삶의 동반자로 새롭게 진화하고 있습니다.
        </p>
        <p>
          휴먼테크놀로지의 가치를 실현시키며, 고객의 신뢰와 믿음에 보답해온
          조일공업㈜은 과거(Past) 기술 터전 마련을 위해 분주히 달려왔던 인고의
          시간을 견디고 오늘(Now)의 성장을 이끌어냈습니다. 우리는 이제 다가올
          스마트 모빌리티 시장의 뉴노멀을 정립하고, 인류의 지속 가능한
          미래(Sustainable Future)로 나아가기 위해 새로운 도약을 준비하고
          있습니다.
        </p>
        <p>
          변화와 혁신의 자세로 대한민국을 넘어 글로벌 자동차 차체부품 생산
          일등기업으로 우뚝 서는 그날까지 조일공업㈜의 도전은 계속될 것입니다.
        </p>
        <img
          src={sign}
          alt="조일공업주식회사 대표 김수열 사인"
          className="absolute right-5 bottom-0"
        />
      </div>
    </div>
  );
};
