import "../layout.css";
import footer_logo from "../../assets/icon/logo-footer.png";
export const FOOTER = () => {
  return (
    <footer className="w-full h-300 box-border flex flex-wrap content-center  sm:justify-center flex-col bg-footer py-10 sm:py-0 sm:gap-y-10 gap-y-8 text-white px-10">
      <div className="text-center">
        <a href="/" className="footer_logo inline-block h-16">
          <img
            src={footer_logo}
            alt="조일공업 주식회사 로고"
            className="h-full inline-block"
          />
        </a>
      </div>
      <address className="text-2xl not-italic text-left sm:text-center sm:space-y-0 space-y-2">
        <span className="afterbar block sm:inline">
          Corporate registration number : 620-81-14739
        </span>
        <span className="afterbar block sm:inline">Tel : 052-280-0900</span>
        <span className="afterbar block sm:inline">
          E-mail : joil_010@joilltd.co.kr
        </span>
        <span className="block sm:inline">
          Address : 43, Hyoseong-ro, Buk-gu, Ulsan (Hyomun-dong)
        </span>
      </address>

      <small className="text-center text-2xl">
        <span className="capitalize">copyright</span>&copy; Joil Co., Ltd.
        <span className="capitalize">all</span> rights reserved.
      </small>
    </footer>
  );
};
