import React from "react";
import video from "../../../assets/video/machine.mp4";
import product1 from "../../../assets/images/business/inner7.jpg";
import product2 from "../../../assets/images/business/inner5.jpg";
import product3 from "../../../assets/images/business/inner6.jpg";
import product4 from "../../../assets/images/business/inner4.jpg";
import { Fade } from "react-awesome-reveal";
import shape from "../../../assets/icon/Frame.png";
import "../../css/business.css";
export const Line = () => {
  return (
    <div className="max-w-screen-lg w-full px-8 pb-32 sm:pb-64 pt-20 sm:pt-52 m-auto">
      <div className="title_logo">
        <img
          src={shape}
          alt="라인에 기계가 물건을 내려놓는 아이콘"
          className="m-auto"
        />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-6xl font-bold text-center">
        <span className="leading-normal">생산라인</span>
      </h3>
      <div className="max-w-screen-lg m-auto ">
        <p className="pt-10 sm:pt-20 text-main1 text-2xl sm:text-3xl leading-normal sm:leading-normal px-5 tranking-tight">
          조일공업㈜은 생산성 향상을 위해 신기술 및 신공법 개발을 위한 R＆D에도
          집중하며, 스틸을 이용한 자동차부품개발/프레스성형/용접(로봇영접/스팟,
          CO2용접) 등을 비롯해 모든 설비시설에 로봇 기술을 활용한 '스마트 팩토리
          시스템'을 구축하고 있습니다.
        </p>
      </div>
      <Fade delay={300} triggerOnce={true}>
        <div id="robot" className="pt-24 sm:pt-48">
          <div className="stt text-center">
            <p className="font-bold text-5xl">로봇 자동화 생산라인</p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          </div>
          <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-16 tracking-tight">
            <img src={product1} alt="프레스 로봇 사진" />
            <p className="text-main1 leading-normal sm:leading-normal text-2xl sm:text-3xl">
              2007년 프레스 로봇 자동화 라인 구축을 시작으로, 2014년 600톤
              프레스 로봇 자동화 라인 및 400톤 트랜스퍼 자동화 라인을 확보하고
              있으며, 현재 다양한 규격의 프레스 40대와 85대의 생산 로봇을
              보유하고 있습니다. 수년간의 자체 연구개발을 거쳐 현대중공업의 6축
              로봇을 활용한 '직교 로봇(6축 로봇) 활용 우레탄 폼 자동화 실링
              장치' 국산화에도 성공했습니다.
            </p>
          </div>
        </div>
        <div id="autostack" className="pt-24 sm:pt-48">
          <div className="stt text-center">
            <p className="font-bold text-5xl">금형 자동화 적재 창고</p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          </div>
          <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-16 tranking-tight">
            <img src={product2} alt="프레스 로봇 사진" />
            <p className="text-main1 leading-normal sm:leading-normal text-2xl sm:text-3xl">
              영남지역 최초로 만들어진 금형 자동화 적재 창고는 프레스 금형
              몰드로 성형 후 자동화적재 창고를 이용한 시스템 최대 800세트까지
              보유가 가능하며, 금형 시간 단축은 물론, 보관 공간 확보와 안전사고
              예방 등에 이르기까지 업무 효율성을 극대화하고 있습니다.
            </p>
          </div>
        </div>
        <div id="amount" className="pt-24 sm:pt-48">
          <div className="stt text-center">
            <p className="font-bold text-5xl">대규모 생산네트워크 구축</p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          </div>
          <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-16 tranking-tight">
            <img src={product3} alt="프레스 로봇 사진" />
            <p className="text-main1 leading-normal sm:leading-normal text-2xl sm:text-3xl">
              울산 미포국가산업단지 내 두 곳의 공장에 이어, 2008년 충남
              예산지역에 자회사 ㈜조일의 설립과 함께 예산공장을 증축하였으며,
              2021년 울산 테크노산업단지 내 5,000평 규모의 전 공정 스마트 자동화
              라인 공장을 신축하는 등 대규모 생산 네트워크를 구축하고 있습니다.
            </p>
          </div>
        </div>
        <div id="smart" className="pt-24 sm:pt-48">
          <div className="stt text-center">
            <p className="font-bold text-5xl">스마트 모빌리티</p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          </div>
          <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-16 tranking-tight">
            <img src={product4} alt="프레스 로봇 사진" />
            <p className="text-main1 leading-normal sm:leading-normal text-2xl sm:text-3xl">
              전기차·수소차 등 친환경 모빌리티에 접목시킬 수 있는 친환경 소재
              개발을 위해 친환경 ISO 기업 인증을 비롯해 IATF 자동차 산업
              품질경영시스템, 안전보건경영시스템(ISO45001),
              품질경영시스템(ISO9001), 국제환경규격(ISO14000) 등 친환경 소재
              개발에 대한 각종 인증을 마쳤으며, 본격적인 부품 양산을 위한 준비에
              박차를 가하고 있습니다.
            </p>
          </div>
        </div>
      </Fade>
    </div>
  );
};
