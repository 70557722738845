import React from "react";
import shape from "../../../assets/icon/Frame.png";
import { Fade } from "react-awesome-reveal";
import { listManagement } from "../../../constants/management_constant";

export const EN_Management = () => {
  return (
    <div className="max-w-screen-xl w-full px-8 pb-32 sm:pb-64 pt-20 sm:pt-52 m-auto">
      <div className="title_logo">
        <img src={shape} alt="icon of robot arm is putting a stuff on line" className="m-auto" />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-5xl sm:text-6xl font-bold text-center">
        <span className="leading-normal">Management activity</span>
      </h3>
      <Fade triggerOnce={true}>
        {listManagement.map((management, index) => {
          return (
            <div key={`management-en-component-${index}`} className="pt-28 sm:pt-52">
              <div className="stt text-center">
                <p className="font-bold text-4xl sm:text-5xl">{`${management.subject_en}`}</p>
                <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
              </div>
              <div className="max-w-screen-lg w-full m-auto">
                <img src={management.url} alt="조일공업 전경 이미지 벽면" />

                <p className="pt-10 whitespace-pre-line sm:pt-24 text-main1 text-2xl sm:text-3xl leading-normal sm:leading-normal px-5 break-all tracking-tight">
                  {`${management.text_en}`}
                </p>
              </div>
            </div>
          );
        })}
      </Fade>
    </div>
  );
};
