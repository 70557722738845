import React from "react";
import { FOOTER } from "../../../layout/eng/footer";
import { SUBHEADER } from "../../../layout/eng/subheader";
import { EN_Facility } from "../../../parts/eng/business/facility";
import { Helmet } from "react-helmet";
export const EN_FACILITY = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="business">
        <EN_Facility />
      </div>
      <FOOTER />
    </main>
  );
};
