import React from "react";
import { FOOTER } from "../../../layout/kor/footer";
import { SUBHEADER } from "../../../layout/kor/subheader";
import { Comm } from "../../../parts/kor/intro/comment";
class COMMENT extends React.Component {
  render() {
    return (
      <main id="content">
        <SUBHEADER />
        <div id="intro">
          <Comm />
        </div>
        <FOOTER />
      </main>
    );
  }
}
export default COMMENT;
