import React from "react";
import "../../css/map.css";
export const EN_BASE = () => {
  return (
    <div className="pt-24 px-4 max-w-screen-lg m-auto">
      <h4 className="text-4xl sm:text-5xl font-bold text-center">
        Joil Co., Ltd.
      </h4>
      <div className="w-36 h-1 bg-gray-500 m-auto mt-12 mb-24"></div>
      <div className="max-w-screen-lg m-auto pb-24">
        <div className="mapRatio relative">
          <iframe
            className="w-full h-full absolute left-0 top-0"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3199.094607556553!2d126.91726309124229!3d36.696264405537256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ae8509ea32a39%3A0xaf5d8e274110bee3!2z7Lap7LKt64Ko64-EIOyYiOyCsOq1sCDrjIDsiKDrqbQg64yA7Iig66GcIDY4NC0xMg!5e0!3m2!1sko!2skr!4v1636357567561!5m2!1sko!2skr"
          ></iframe>
        </div>
      </div>
      <table className=" info border-t-2 border-b-2 w-full border-gray-700">
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Date established
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">2008. 04. 01.</td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Business type
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">
            Car body part production
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Factory size
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">
            Site area 290,000 sqft / Building area 118,490 sqft
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Key items
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5 uppercase">
            240 items including RAIL ROOF CTR, RR FEM
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Main customers
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">
            Hyundai Motor Company, Kia Motors, Hyundai Mobis, etc.
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Address
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">
            684-12, Daesul-ro, Daesul-myeon, Yesan-gun, Chungcheongnam-do
          </td>
        </tr>
        <tr>
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Contact No.
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">052-280-0900</td>
        </tr>
      </table>
    </div>
  );
};
