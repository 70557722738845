import React from "react";
import "../../css/map.css";
export const BASE = () => {
  return (
    <div className="pt-24 px-4 max-w-screen-lg m-auto">
      <h4 className="text-4xl sm:text-5xl font-bold text-center">(주)조일</h4>
      <div className="w-36 h-1 bg-gray-500 m-auto mt-12 mb-24"></div>
      <div className="max-w-screen-lg m-auto pb-24">
        <div className="mapRatio relative">
          <iframe
            className="w-full h-full absolute left-0 top-0"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3199.094607556553!2d126.91726309124229!3d36.696264405537256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ae8509ea32a39%3A0xaf5d8e274110bee3!2z7Lap7LKt64Ko64-EIOyYiOyCsOq1sCDrjIDsiKDrqbQg64yA7Iig66GcIDY4NC0xMg!5e0!3m2!1sko!2skr!4v1636357567561!5m2!1sko!2skr"
          ></iframe>
        </div>
      </div>
      <table className=" info border-t-2 border-b-2 w-full border-gray-700">
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose tracking-tight">
            설립연월일
          </th>
          <td className="font  text-2xl sm:text-3xl w-3/5 tracking-tight">
            2008. 04. 01.
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose tracking-tight">
            업종
          </th>
          <td className="font  text-2xl sm:text-3xl w-3/5 tracking-tight">
            자동차 차체부품제조
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose tracking-tight">
            공장규모
          </th>
          <td className="font  text-2xl sm:text-3xl w-3/5 tracking-tight">
            대지 8,150평 / 건평 3,330평
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose tracking-tight">
            주요품목
          </th>
          <td className="font  text-2xl sm:text-3xl w-3/5 tracking-tight uppercase">
            rail roof CTR, RR FEM 외 240종
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose tracking-tight">
            주거래처
          </th>
          <td className="font  text-2xl sm:text-3xl w-3/5 tracking-tight">
            현대자동차, 기아자동차, 현대모비스 등
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose tracking-tight">
            주소
          </th>
          <td className="font  text-2xl sm:text-3xl w-3/5 tracking-tight">
            충남 예산군 대술면 대술로 684-12
          </td>
        </tr>
        <tr>
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose tracking-tight">
            연락처
          </th>
          <td className="font  text-2xl sm:text-3xl w-3/5 tracking-tight">
            052-280-0900
          </td>
        </tr>
      </table>
    </div>
  );
};
