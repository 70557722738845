import React from "react";
import grade from "../../../assets/images/recruit/grade.png";
import { Fade } from "react-awesome-reveal";
export const Hrsystem = () => {
  return (
    <div className="pt-24 pb-32 sm:pb-64 px-5">
      <h4 className="text-4xl sm:text-5xl font-bold text-center">인사정책</h4>
      <div className="w-36 h-1 bg-gray-500 m-auto mt-12 mb-24"></div>
      <div className="hrsystem">
        <div className="pt-24 max-w-screen-lg m-auto my-0">
          <Fade delay={300} triggerOnce={true}>
            <img
              src={grade}
              alt="직급순서 그림. 사원 , 주임 , 대리 , 과장 , 차장 , 부장"
            />
          </Fade>
        </div>
        <Fade delay={300} triggerOnce={true}>
          <p className="pt-24 sm:pt-48 text-4xl md:text-5xl text-center keep-all tracking-tight">
            인재중시의 기업가치를 추구합니다.
          </p>
          <p className="pt-24 sm:pt-48 text-3xl text-center leading-normal tracking-tight">
            조일공업㈜는 인간 중심 경영이념 아래 인재를 중시하고 존중하는
            기업문화를 추구합니다.
            <br />
            개인의 능력과 업무실적에 따라 철저하고 공정한 평가를 통한 객관적
            인사제도를 시행하고 있습니다.
          </p>
          <div className="pt-24 sm:pt-48 grid grid-col-1 md:grid-cols-2 gap-x-11 gap-y-24">
            <div>
              <div className="bg-main1 text-white text-3xl md:text-4xl font-bold text-center py-5 leading-normal">
                정기승진
              </div>
              <div className="h-3 bg-gray-300"></div>
              <div className="px-12 h-56 leading-normal border border-gray-400 border-t-0 flex flex-wrap content-center justify-center ">
                <p className="text-2xl md:text-3xl text-main1 tracking-tight">
                  년 1회(12월) 기준, 승진 소요 년 한을 초과한자로서, 근무 성적이
                  우수하고 상위직급 수행 능력이 있다고 판단되는 자를 대상으로
                  임용권자가 승진시킨다.
                </p>
              </div>
            </div>
            <div>
              <div className="bg-main1 text-white text-3xl md:text-4xl font-bold text-center py-5 leading-normal">
                특별승진
              </div>
              <div className="h-3 bg-gray-300"></div>
              <div className="px-12 h-56 leading-normal border border-gray-400 border-t-0 flex flex-wrap content-center justify-center ">
                <p className="text-2xl md:text-3xl text-main1 tracking-tight">
                  회사의 필요에 따라 임용권자가 예외적으로 승진 시킬 수 있다.
                </p>
              </div>
            </div>
            <div>
              <div className="bg-main1 text-white text-3xl md:text-4xl font-bold text-center py-5 leading-normal">
                급여체계
              </div>
              <div className="h-3 bg-gray-300"></div>
              <div className="px-12 h-56 leading-normal border border-gray-400 border-t-0 flex flex-wrap content-center justify-center ">
                <p className="text-2xl md:text-3xl text-main1 tracking-tight ">
                  사무직 : 연봉제 (성과와 역량에 따라 매년 차등 적용)
                  <br />
                  생산직 : 월급직 또는 시급직
                </p>
              </div>
            </div>
            <div>
              <div className="bg-main1 text-white text-3xl md:text-4xl font-bold text-center py-5 leading-normal">
                복리후생
              </div>
              <div className="h-3 bg-gray-300"></div>
              <div className="px-12 h-56 leading-normal border border-gray-400 border-t-0 flex flex-wrap content-center justify-center ">
                <p className="text-2xl md:text-3xl text-main1 tracking-tight ">
                  유치원, 초·중·고·대학교 자녀 학자금 지원 매년 건강검진 지원
                  기타 사내규정에 의한 복리지원
                </p>
              </div>
            </div>
            <div>
              <div className="bg-main1 text-white text-3xl md:text-4xl font-bold text-center py-5 leading-normal">
                기타
              </div>
              <div className="h-3 bg-gray-300"></div>
              <div className="px-12 h-56 leading-normal border border-gray-400 border-t-0 flex flex-wrap content-center justify-center ">
                <p className="text-2xl md:text-3xl text-main1 tracking-tight ">
                  경력직 입사의 경우, 임용권자의 판단에 따라 직급을 부여하고
                  이는 향후 회사 내규에 의거한 승급 년 한에 준한다.
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};
