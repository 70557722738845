import React from "react";
import { FOOTER } from "../../../layout/kor/footer";
import { SUBHEADER } from "../../../layout/kor/subheader";
import { Certification } from "../../../parts/kor/intro/certification";
export const CERTIFICATION = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="intro">
        <Certification />
      </div>
      <FOOTER />
    </main>
  );
};
