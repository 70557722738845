import React from "react";
import devel from "../../../assets/icon/Innovation.png";
import clock from "../../../assets/icon/Clock.png";
import united from "../../../assets/icon/United.png";
import puzzle from "../../../assets/icon/Puzzle.png";
import care from "../../../assets/icon/Care.png";
import outstanding from "../../../assets/icon/Outstanding.png";
import discussion from "../../../assets/icon/Discussion.png";
import { Fade, Slide } from "react-awesome-reveal";
import "../../css/recruit.css";
export const Work = () => {
  return (
    <div className="pt-24 pb-32 sm:pb-64 px-4">
      <h4 className="text-4xl sm:text-5xl font-bold text-center">직무소개</h4>
      <div className="w-36 h-1 bg-gray-500 m-auto mt-12 mb-24"></div>
      <div className="work space-y-10 sm:space-y-24">
        <Fade direction="down" triggerOnce={true}>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-60 sm:h-80">
            <div className="bg-gray-400 h-full recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={devel}
                alt="개발팀을 형상화한 아이콘 , 사람 머리위 전구"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center">
                개발팀
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-2xl sm:text-3xl text-main1 sm:leading-relaxed tracking-tight">
                신규 차종에 대한 부품 개발을 주 업무로, 고객 요구수준에 맞는
                제품을 개발하여 신규 제품이 안정적으로 양산될 수 있도록
                지원합니다.
              </p>
            </div>
          </div>

          <div className="max-w-screen-lg m-auto recruit-work-grid h-60 sm:h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={clock}
                alt="생산관리팀 형상화한 아이콘 , 시계와 사람 아이콘"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center">
                생산관리팀
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-2xl sm:text-3xl  text-main1 sm:leading-relaxed tracking-tight">
                다년간 축적된 기술 노하우를 바탕으로 최고 품질의 제품을
                생산합니다.
              </p>
            </div>
          </div>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-60 sm:h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={united}
                alt="생산기술팀 형상화한 아이콘 , 사람의 손을 모은 아이콘"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center">
                생산기술팀
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-2xl sm:text-3xl  text-main1 sm:leading-relaxed tracking-tight">
                최소의 비용과 노동력으로 최고 수준의 제품 생산이 가능하도록
                공정을 설계하고 개선하며, 기타 설비 및 라인을 관리합니다.
              </p>
            </div>
          </div>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-60 sm:h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={puzzle}
                alt="자재관리팀 형상화한 아이콘 , 퍼즐조각 아이콘"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center">
                자재관리팀
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-2xl sm:text-3xl  text-main1 sm:leading-relaxed tracking-tight">
                생산에 필요한 원자재 및 부자재를 최적의 납기로 구매, 공급합니다.
              </p>
            </div>
          </div>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-60 sm:h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={care}
                alt="영업관리팀 형상화한 아이콘 , 사람손 위의 사람 아이콘"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center">
                영업관리팀
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-2xl sm:text-3xl l text-main1 sm:leading-relaxed tracking-tight">
                영업목표 달성을 위해 지속적인 매출관리 업무를 수행하며, 고객이
                만족할 수 있는 양질의 제품을 적기에 공급하여 매출 증대에
                이바지합니다.
              </p>
            </div>
          </div>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-60 sm:h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={outstanding}
                alt="품질경영팀 형상화한 아이콘 , 사람위에 위로 항햐는 화살이 있어 품질이 상승한다는 느낌의 아이콘"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center">
                품질경영팀
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-2xl sm:text-3xl  text-main1 sm:leading-relaxed tracking-tight">
                회사 업무의 기본이 되는 회사 표준 업무 프로세스 및 품질경영 인증
                업무를 관장하며, 협력사와의 동방성장을 목적으로 협력사
                지도＆점검, 평가를 수행합니다.
              </p>
            </div>
          </div>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-60 sm:h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={discussion}
                alt="품질보증팀 형상화한 아이콘 , 세사람이 이야기 하고 있는 아이콘"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center">
                품질보증팀
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-2xl sm:text-3xl  text-main1 sm:leading-relaxed tracking-tight">
                외주 입고품 및 생산제품의 품질이 고객요구수준에 적합한지
                관리하며, 출하된 제품의 품질을 관리 및 보증하고 있습니다.
              </p>
            </div>
          </div>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-60 sm:h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={devel}
                alt="원가팀 형상화한 아이콘 , 세사람이 이야기 하고 있는 아이콘"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center">
                원가팀
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-2xl sm:text-3xl  text-main1 sm:leading-relaxed tracking-tight">
                신제품 개발 및 양산품 생산에 필요한 원가를 산정하여 회사 이익을
                극대화하기 위한 업무를 담당합니다.
              </p>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};
