import React, { useState } from "react";
import "../../css/business.css";
import video from "../../../assets/video/assemble.mp4";
import close from "../../../assets/icon/xicon.png";
import { Fade } from "react-awesome-reveal";
import shape from "../../../assets/icon/Frame.png";
import { listMovingProduct, listBodyProduct, listChassisProduct, listTrimProduct, listFrProduct } from "../../../constants/product_constant";

export const EN_Products = () => {
  const [over, setover] = useState<boolean>(false);
  const overHandler = (e: number) => {
    if (e == 0) {
      setover(() => {
        return false;
      });
    } else {
      setover(() => {
        return true;
      });
    }
  };
  const [tab, settab] = useState<number>(0);
  const settabHandler = (num: number) => {
    settab(() => {
      return num;
    });
  };
  const mouseTrack = (e: any) => {
    overHandler(1);
    let floatBox = document.getElementsByClassName("MouseOverImage")[0] as HTMLElement;
    if (window.innerWidth - 650 < e.pageX) {
      floatBox.style.left = e.pageX - 600 + "px";
    } else {
      floatBox.style.left = e.pageX + "px";
    }
    floatBox.style.top = e.pageY + "px";
    floatBox.style.backgroundImage = "url(" + e.target.src + ")";
  };
  return (
    <div className="max-w-screen-xl m-auto pt-20 sm:pt-52">
      <div className="title_logo">
        <img src={shape} alt="icon of robot arm is putting a stuff on line" className="m-auto" />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-5xl lg:text-6xl font-bold text-center pb-24 sm:pb-52">
        <span className="leading-normal">Production item</span>
      </h3>
      <ul className="listborder prd-m-border">
        <li className={`box-border text-3xl sm:text-4xl inline-block w-1/2 md:w-1/3 h-28 ${tab == 0 && "selectList"}`}>
          <button className="w-full h-full" onClick={() => settabHandler(0)}>
            <a href="#list" className="w-full h-full flex flex-wrap content-center justify-center">
              production list
            </a>
          </button>
        </li>
        <li className={`box-border text-3xl sm:text-4xl inline-block w-1/2 md:w-1/3 h-28 ${tab == 1 && "selectList"}`}>
          <button className="w-full h-full" onClick={() => settabHandler(1)}>
            <a href="#movg" className="w-full h-full flex flex-wrap content-center justify-center">
              mov'g part
            </a>
          </button>
        </li>
        <li className={`box-border text-3xl sm:text-4xl inline-block w-1/2 md:w-1/3 h-28 ${tab == 2 && "selectList"}`}>
          <button className="w-full h-full" onClick={() => settabHandler(2)}>
            <a href="#body" className="w-full h-full flex flex-wrap content-center justify-center">
              body part
            </a>
          </button>
        </li>
        <li className={`box-border text-3xl sm:text-4xl inline-block w-1/2 md:w-1/3 h-28 ${tab == 3 && "selectList"}`}>
          <button className="w-full h-full" onClick={() => settabHandler(3)}>
            <a href="#chassis" className="w-full h-full flex flex-wrap content-center justify-center">
              chassis part
            </a>
          </button>
        </li>
        <li className={`box-border text-3xl sm:text-4xl inline-block w-1/2 md:w-1/3 h-28 ${tab == 4 && "selectList"}`}>
          <button className="w-full h-full" onClick={() => settabHandler(4)}>
            <a href="#trim" className="w-full h-full flex flex-wrap content-center justify-center">
              trim
            </a>
          </button>
        </li>
        <li className={`box-border text-3xl sm:text-4xl inline-block w-1/2 md:w-1/3 h-28 ${tab == 5 && "selectList"}`}>
          <button className="w-full h-full" onClick={() => settabHandler(5)}>
            <a href="#fr" className="w-full h-full flex flex-wrap content-center justify-center">
              FR end module
            </a>
          </button>
        </li>
      </ul>
      <div id="list" className="max-w-screen-lg m-auto py-24">
        <video src={video} autoPlay loop muted className="w-full"></video>
      </div>
      <div id="movg" className="py-16 sm:py-24">
        <div className="stt text-center">
          <p className="font-bold text-4xl sm:text-5xl capitalize">
            <span className="uppercase">mov's part</span>
          </p>
          <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
        </div>
        <div className="px-5 sm:px-0 pt-0 sm:pt-24 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-8">
          {listMovingProduct.map((con) => (
            <Fade key={con.code} triggerOnce={true} delay={300}>
              <div>
                <p className="h-36 sm:h-auto py-10 text-center text-2xl lg:text-3xl text-303030">{con.name}</p>
                <img
                  src={con.path}
                  alt={con.name}
                  className="border border-gray-300 hover:opacity-50 cursor-pointer"
                  onClick={(e) => {
                    mouseTrack(e);
                  }}
                />
              </div>
            </Fade>
          ))}
        </div>
      </div>
      <div id="body" className="py-16 sm:py-24">
        <div className="stt text-center">
          <p className="font-bold text-4xl sm:text-5xl capitalize">
            <span className="uppercase">body part</span>
          </p>
          <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
        </div>
        <div className="px-5 sm:px-0 pt-0 sm:pt-24 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-8">
          {listBodyProduct.map((con) => (
            <Fade key={con.code} triggerOnce={true} delay={300}>
              <div key={con.code}>
                <p className="h-36 sm:h-auto py-10 text-center text-2xl lg:text-3xl text-303030">{con.name}</p>
                <img
                  src={con.path}
                  alt={con.name}
                  className="border border-gray-300 hover:opacity-50 cursor-pointer"
                  onClick={(e) => {
                    mouseTrack(e);
                  }}
                />
              </div>
            </Fade>
          ))}
        </div>
      </div>
      <div id="chassis" className="py-16 sm:py-24">
        <div className="stt text-center">
          <p className="font-bold text-4xl sm:text-5xl capitalize">
            shassis <span className="uppercase">part</span>
          </p>
          <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
        </div>
        <div className="px-5 sm:px-0 pt-0 sm:pt-24 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-8">
          {listChassisProduct.map((con) => (
            <Fade key={con.code} triggerOnce={true} delay={300}>
              <div key={con.code}>
                <p className="h-36 sm:h-auto py-10 text-center text-2xl lg:text-3xl text-303030">{con.name}</p>
                <img
                  src={con.path}
                  alt={con.name}
                  className="border border-gray-300 hover:opacity-50 cursor-pointer"
                  onClick={(e) => {
                    mouseTrack(e);
                  }}
                />
              </div>
            </Fade>
          ))}
        </div>
      </div>
      <div id="trim" className="py-16 sm:py-24">
        <div className="stt text-center">
          <p className="font-bold text-4xl sm:text-5xl capitalize">
            trim <span className="uppercase">part</span>
          </p>
          <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
        </div>
        <div className="px-5 sm:px-0 pt-0 sm:pt-24 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-8">
          {listTrimProduct.map((con) => (
            <Fade key={con.code} triggerOnce={true} delay={300}>
              <div key={con.code}>
                <p className="h-36 sm:h-auto py-10 text-center text-2xl lg:text-3xl text-303030">{con.name}</p>
                <img
                  src={con.path}
                  alt={con.name}
                  className="border border-gray-300 hover:opacity-50 cursor-pointer"
                  onClick={(e) => {
                    mouseTrack(e);
                  }}
                />
              </div>
            </Fade>
          ))}
        </div>
      </div>
      <div id="fr" className="py-16 sm:py-24">
        <div className="stt text-center">
          <p className="font-bold text-4xl sm:text-5xl capitalize">
            <span className="uppercase">FR end module</span>
          </p>
          <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
        </div>
        <div className="px-5 sm:px-0 pt-0 sm:pt-24 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-8">
          {listFrProduct.map((con) => (
            <Fade key={con.code} triggerOnce={true} delay={300}>
              <div key={con.code}>
                <p className="h-36 sm:h-auto py-10 text-center text-2xl lg:text-3xl text-303030">{con.name}</p>
                <img
                  src={con.path}
                  alt={con.name}
                  className="border border-gray-300 hover:opacity-50 cursor-pointer"
                  onClick={(e) => {
                    mouseTrack(e);
                  }}
                />
              </div>
            </Fade>
          ))}
        </div>
      </div>
      <div className={`MouseOverImage border border-gray-300 ${over ? "block" : "hidden"} `}>
        <button
          className="absolute right-5 top-5 w-8 h-8"
          onClick={() => {
            overHandler(0);
          }}
        >
          <img src={close} alt="big image close" className="w-full" />
        </button>
      </div>
    </div>
  );
};
