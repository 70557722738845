import React, { useState } from "react";
import { FOOTER } from "../../layout/kor/footer";
import ReactPageScroller from "react-page-scroller";
import "../page.css";
import { HEADER } from "../../layout/kor/header";
import { Main } from "../../parts/kor/home/main";
import { Facility } from "../../parts/kor/home/facilities";
import { Product } from "../../parts/kor/home/products";
import { Strength } from "../../parts/kor/home/strength";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
export const HOME = () => {
  // Last scroll
  const [lastscroll, setscroll] = useState<boolean>(false);
  const banscrollHandler = () => {
    setscroll(() => {
      return false;
    });
  };
  const okscrollHandler = () => {
    document.body.classList.add("noScrollBar");
    setscroll(() => {
      return true;
    });
  };
  return isBrowser ? (
    <main id="content" className={`pc ${lastscroll ? "" : "banScroll"}`}>
      <HEADER />
      <ReactPageScroller
        animationTimer={500}
        pageOnChange={function scrollcontrol(number) {
          if (number == 2) {
            var slide = document
              .getElementsByClassName("prodS")[0]
              .getElementsByClassName("swiper-slide");
            for (let i = 0; i < slide.length; i++) {
              if (slide[i].classList.contains("swiper-slide-active")) {
                slide[i].getElementsByTagName("video")[0].play();
              } else {
                slide[i].getElementsByTagName("video")[0].currentTime = 0;
                slide[i].getElementsByTagName("video")[0].pause();
              }
            }
          } else if (number == 3) {
            okscrollHandler();
          } else {
            banscrollHandler();
          }
        }}
      >
        <Main />
        <Facility />
        <Product />
        <Strength />
      </ReactPageScroller>
      <FOOTER />
    </main>
  ) : (
    <main id="content" className="mobile">
      <HEADER />
      <Main />
      <Facility />
      <Product />
      <Strength />

      <FOOTER />
    </main>
  );
};
