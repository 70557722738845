import React from "react";
import video from "../../../assets/video/machine.mp4";
import product1 from "../../../assets/images/business/inner7.jpg";
import product2 from "../../../assets/images/business/inner5.jpg";
import product3 from "../../../assets/images/business/inner6.jpg";
import product4 from "../../../assets/images/business/inner4.jpg";
import { Fade } from "react-awesome-reveal";
import shape from "../../../assets/icon/Frame.png";
import "../../css/business.css";
export const EN_Line = () => {
  return (
    <div className="max-w-screen-lg w-full px-8 pb-32 sm:pb-64 pt-20 sm:pt-52 m-auto">
      <div className="title_logo">
        <img
          src={shape}
          alt="icon of robot arm is putting a stuff on line"
          className="m-auto"
        />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-6xl font-bold text-center">
        <span className="leading-normal">Production line </span>
      </h3>
      <div className="max-w-screen-lg m-auto pt-32 sm:pt-52">
        <video src={video} autoPlay loop muted className="w-full"></video>
        <p className="pt-10 sm:pt-20 text-main1 text-2xl sm:text-3xl tracking-tight leading-normal sm:leading-normal px-5">
          &nbsp;&nbsp;Joil Co., Ltd. is also focusing on R＆D to develop new
          technology and production methods to enhance productivity, and have
          established a ‘smart factory system’ using robot technology for all
          facilities including car part development/press shaping/welding(robot
          welding/spot, CO2 welding) using steel, etc.
        </p>
      </div>
      <Fade delay={300} triggerOnce={true}>
        <div id="robot" className="pt-24 sm:pt-48">
          <div className="stt text-center">
            <p className="font-bold text-5xl">
              Robot automated production line
            </p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          </div>
          <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-16">
            <img src={product1} alt="picture of press robot" />
            <p className="text-main1 text-2xl sm:text-3xl tracking-tight leading-normal sm:leading-normal">
              &nbsp;&nbsp;starting from establishing a press robot automated
              line in 2007, 600-ton press robot automation line and 400-ton
              transfer automation line have been acquired as of 2014, currently
              owning 40 presses of various dimensions and 85 production robots.
              Through years of self R＆D, we have also succeeded in localization
              of 'urethane foam automated sealing equipment using a cartesian
              robot(6-axis robot)
            </p>
          </div>
        </div>
        <div id="autostack" className="pt-24 sm:pt-48">
          <div className="stt text-center">
            <p className="font-bold text-5xl">Mold automated loading storage</p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          </div>
          <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-16">
            <img src={product2} alt="picture of storage " />
            <p className="text-main1 text-2xl sm:text-3xl tracking-tight leading-normal sm:leading-normal">
              &nbsp;&nbsp; The first mold automated loading storage in Youngnam
              can hold up to 800 sets with automated loading storage after
              shaping with press mold, and is magnifying work efficiency from
              reducing molding time to acquiring storage space and preventing
              safety accidents.
            </p>
          </div>
        </div>
        <div id="amount" className="pt-24 sm:pt-48">
          <div className="stt text-center">
            <p className="font-bold text-5xl">
              Establishing large-scale production network
            </p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          </div>
          <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-16">
            <img src={product3} alt="picture of press robot" />
            <p className="text-main1 text-2xl sm:text-3xl tracking-tight leading-normal sm:leading-normal">
              &nbsp;&nbsp;Along with 2 factories in the Ulsan Mipo National
              Industrial Park, Yesan Factory was added in 2008 at Yesan,
              Chungnam with the foundation of subsidiary Joil Co., Ltd., and a
              large-scale production network is being established such as new
              construction of all process smart automated line factory of size
              178,000 sqft in the Ulsan Techno Industrial Complex in 2021.
            </p>
          </div>
        </div>
        <div id="smart" className="pt-24 sm:pt-48">
          <div className="stt text-center">
            <p className="font-bold text-5xl">Smart mobility</p>
            <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
          </div>
          <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 md:gap-x-16">
            <img src={product4} alt="picture of press robot" />
            <p className="text-main1 text-2xl sm:text-3xl tracking-tight leading-normal sm:leading-normal">
              &nbsp;&nbsp; To develop eco-friendly material that can be applied
              to eco-friendly mobility such as electric car/hydrogen car, etc.,
              we have completed various certifications on eco-friendly material
              development such as eco-friendly ISO company certification, IATF
              car industry quality management system, safety/health management
              system (ISO45001), quality management system (ISO9001),
              environmental management standards (ISO14000), etc. and are
              speeding up preparations for proper part mass-production.
            </p>
          </div>
        </div>
      </Fade>
    </div>
  );
};
