import React from "react";
import devel from "../../../assets/icon/Innovation.png";
import clock from "../../../assets/icon/Clock.png";
import united from "../../../assets/icon/United.png";
import puzzle from "../../../assets/icon/Puzzle.png";
import care from "../../../assets/icon/Care.png";
import outstanding from "../../../assets/icon/Outstanding.png";
import discussion from "../../../assets/icon/Discussion.png";
import { Fade, Slide } from "react-awesome-reveal";
import "../../css/recruit.css";
export const EN_Work = () => {
  return (
    <div className="pt-24 pb-32 sm:pb-64 px-4">
      <h4 className="text-4xl sm:text-5xl font-bold text-center">
        Job introduction
      </h4>
      <div className="w-36 h-1 bg-gray-500 m-auto mt-12 mb-24"></div>
      <div className="work space-y-10 sm:space-y-24">
        <Fade direction="down" triggerOnce={true}>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-80">
            <div className="bg-gray-400 h-full recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={devel}
                alt="developer icon , a bulb above person head"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center px-2">
                Development team
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-xl sm:text-3xl text-main1 sm:leading-relaxed tracking-tight">
                The main work involves part development for new automobile
                models where products fitting the customer’s demands are
                developed so that new products can be stably mass-produced.
              </p>
            </div>
          </div>

          <div className="max-w-screen-lg m-auto recruit-work-grid h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={clock}
                alt="Production management system icon, a clock and a person"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center px-2">
                Production management system
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-xl sm:text-3xl text-main1 sm:leading-relaxed tracking-tight">
                Produces top-quality products based on years of technical
                know-how.
              </p>
            </div>
          </div>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={united}
                alt="Production technology team icon , three hands put on each other hand "
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center px-2">
                Production technology team
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-xl sm:text-3xl text-main1 sm:leading-relaxed tracking-tight">
                Designs and improves processes so that top-quality product
                manufacturing is possible with minimum costs and labor, and
                manages other facilities and lines.
              </p>
            </div>
          </div>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={puzzle}
                alt="Resource management team , a piece of puzzle"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center px-2">
                Resource management team
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-xl sm:text-3xl text-main1 sm:leading-relaxed tracking-tight">
                Purchases and supplies the raw and subsidiary materials required
                for production with optimal payment.
              </p>
            </div>
          </div>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={care}
                alt="Sales management team , a person on big hand"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center px-2">
                Sales management team
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-xl sm:text-3xl text-main1 sm:leading-relaxed tracking-tight">
                Performs constant sales management to achieve sales goals and
                contributes to increase in sales with timely supply of
                high-quality products for customer satisfaction.
              </p>
            </div>
          </div>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={outstanding}
                alt="Quality management team, arrow directing upwards above a person head"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center px-2">
                Quality management team
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-xl sm:text-3xl text-main1 sm:leading-relaxed tracking-tight">
                Directs the basics of company tasks – company standard work
                process and quality management certification, and performs
                partner guidance, inspection, and evaluation for mutual growth.
              </p>
            </div>
          </div>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={discussion}
                alt="Quality assurance team , three mans are talking"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center px-2">
                Quality assurance team
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-xl sm:text-3xl text-main1 sm:leading-relaxed tracking-tight">
                Manages whether the quality of outsourcing and manufactured
                products fit the customer’s demands, and manages/assures the
                quality of released products.
              </p>
            </div>
          </div>
          <div className="max-w-screen-lg m-auto recruit-work-grid h-80">
            <div className="bg-gray-400 h-full  recruit-work-round-left flex flex-col flex-wrap justify-center content-center">
              <img
                src={devel}
                alt="cost team icon , three mans are talking"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="mt-10 text-2xl sm:text-4xl font-bold text-main1 text-center px-2">
                Production cost team
              </p>
            </div>
            <div className="h-full border sm:border-l-0 border-gray-400 recruit-work-round-right flex flex-wrap content-center justify-center">
              <p className="px-5 sm:px-24 text-xl sm:text-3xl text-main1 sm:leading-relaxed tracking-tight">
                Calculates the production cost required for the development and
                mass-production of new products to maximize company profit.
              </p>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};
