import React from "react";
import shape from "../../../assets/icon/Shape.png";
import sign from "../../../assets/images/intro/sign.png";
export const EN_Comm = () => {
  return (
    <div className="max-w-screen-lg w-full px-10 pb-32 pt-20 md:pt-48 m-auto">
      <div className="title_logo">
        <img src={shape} alt="icon of shaking hands" className="m-auto" />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-5xl sm:text-6xl font-bold text-center">
        <span className="leading-normal capitalize">greetings</span>
      </h3>
      <div className="stt pt-20 md:pt-48 text-center">
        <p className="font-bold text-3xl sm:text-4xl">
          Past, Now... and Sustainable Future!
        </p>
        <div className="bg-gray-300 w-36 h-2 m-auto my-12"></div>
        <p className="font-bold text-3xl sm:text-4xl">
          We sincerely welcome your visit to Joil Co., Ltd.‘s homepage.
        </p>
      </div>
      <div className="relative mainText mt-28 md:mt-56 space-y-12 text-2xl sm:text-3xl leading-normal sm:leading-normal pb-48 tracking-tight">
        <p>Hello.</p>
        <p>This is Kim Su Yeol, CEO of Joil Co., Ltd.</p>
        <p>
          Car part production specialized company Joil Co., Ltd., founded in
          1997, has continued to grow and develop by practicing creative
          thinking, burning passion, and challenging mindset looking one step
          into the future to achieve the clear goal of ‘top car body part
          production company’.
        </p>
        <p>
          Based on the three management ideologies of responsibility management,
          talent management, and technology management, we have supplied high
          quality/high-efficiency car bodies, chassis, assemblies, seat frames,
          etc. to key domestic/overseas complete car markets and contributed to
          the development of Korea’s car industry.
        </p>
        <p>
          With technical know-how accumulated for years and production cost
          competitiveness from <span className="uppercase">progressive</span>＆
          <span className="uppercase">transfer</span> mold design and
          manufacturing abilities, Joil Co., Ltd. products produced through a
          strict quality assurance system is our effort and promise to realize
          customer satisfaction.
        </p>
        <p>
          After being selected as an outstanding manufacturing information
          system company in September 2005, we have been certified as Inno-biz
          in October 2006 and selected as a global leading company by KICC in
          2016 to enter the hall of fame. Joil Co., Ltd., recognized for its
          growth potential by being selected as an Ulsan star company in 2018,
          is currently operating two factories in the Ulsan Mipo National
          Industrial Park, and is increasing the production line by extending
          into the Yesan Factory with the foundation of subsidiary Joil Co.,
          Ltd. at Yesan, Chungnam in 2008.
        </p>
        <p>
          Based on this, we became the first primary partner of global car
          companies Hyundai Motor Company/Kia Motors to establish a robot
          production automation line and are leading the self-sufficient part
          production trend in Korea by having the first mold automation loading
          storage in Youngnam, etc.
        </p>
        <p>
          With the start of the 4th industrial revolution, cars are going beyond
          simple means of transportation to evolve into companions in life.
        </p>
        <p>
          Joil Co., Ltd., which realized the value of human technology and
          responded to the faith and trust of the clients, have endured working
          towards establishing a technical base in the past and achieved growth
          we see now. We are now preparing for a new leap to establish the new
          pinnacle in the upcoming smart mobility market, and to move towards
          humanity’s sustainable future.
        </p>
        <p>
          Until the day we become the top global car body part production
          company with an attitude of change and innovation, the challenge of
          Joil Co., Ltd. will continue.
        </p>
        <img
          src={sign}
          alt="Joil Co., Ltd. CEO Kim Su Yeol signature"
          className="absolute right-5 bottom-0"
        />
      </div>
    </div>
  );
};
