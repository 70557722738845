import React from "react";
import { FOOTER } from "../../../layout/eng/footer";
import { SUBHEADER } from "../../../layout/eng/subheader";
import shape from "../../../assets/icon/human.png";
import { EN_Announce } from "../../../parts/eng/recruit/announce";
import { Helmet } from "react-helmet";
export const EN_ANNOUNCEMENT = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="recruit" className="max-w-screen-xl m-auto pt-132 pb-64">
        <div className="title_logo">
          <img
            src={shape}
            alt="icon of a person sit on chair"
            className="m-auto"
          />
          <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
        </div>
        <h3 className="text-5xl lg:text-6xl font-bold text-center pb-132">
          <span className="leading-normal">Recruitment guide</span>
        </h3>
        <EN_Announce />
      </div>
      <FOOTER />
    </main>
  );
};
