import React, { useState } from "react";
import { Link } from "react-router-dom";
import { en_divide, url } from "../../apollo";
import { FOOTER } from "../../layout/eng/footer";
import { SUBHEADER } from "../../layout/eng/subheader";
import { EN_BASE } from "../../parts/eng/map/base";
import { EN_BONSA } from "../../parts/eng/map/bonsa";
import { EN_YEONAM } from "../../parts/eng/map/yeonam";
import { useReactiveVar } from "@apollo/client";
import shape from "../../assets/icon/car.png";
export const EN_MAP = () => {
  const pathname = useReactiveVar(url);
  let numbering = 0;
  console.log(en_divide(pathname).subValue);
  if (en_divide(pathname).subValue == "Joil Co., Ltd. HQ") {
    numbering = 0;
  } else if (en_divide(pathname).subValue == "Yeonam Factory") {
    numbering = 1;
  } else if (en_divide(pathname).subValue == "Joil Co., Ltd.") {
    numbering = 2;
  } else if (en_divide(pathname).subValue == "") {
    numbering = 0;
  }
  const [factory, setfactory] = useState<number>(numbering);
  const factoryHandler = (num: number) => {
    setfactory(() => {
      return num;
    });
  };
  return (
    <main id="content">
      <SUBHEADER />
      <div
        id="map"
        className="max-w-screen-xl m-auto pt-20 pb-32 sm:pb-64 sm:pt-52"
      >
        <div className="title_logo">
          <img src={shape} alt="자동차 아이콘" className="m-auto" />
          <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
        </div>
        <h3 className="text-5xl lg:text-6xl font-bold text-center pb-28 sm:pb-52">
          <span className="leading-normal">Directions</span>
        </h3>
        <ul className="listborder map-m-border">
          <li
            className={`box-border text-3xl sm:text-4xl align-middle inline-block w-1/3 h-36 sm:h-28 ${
              factory == 0 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(0)}>
              <Link
                to="/en/map/base"
                className="w-full h-full flex flex-wrap content-center justify-center"
              >
                Joil Co., Ltd. HQ
              </Link>
            </button>
          </li>
          <li
            className={`box-border text-3xl sm:text-4xl align-middle inline-block w-1/3 h-36 sm:h-28 ${
              factory == 1 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(1)}>
              <Link
                to="/en/map/yeonam"
                className="w-full h-full flex flex-wrap content-center justify-center"
              >
                Yeonam Factory
              </Link>
            </button>
          </li>
          <li
            className={`box-border text-3xl sm:text-4xl align-middle inline-block w-1/3 h-36 sm:h-28 ${
              factory == 2 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(2)}>
              <Link
                to="/en/map/joil"
                className=" w-full h-full flex flex-wrap content-center justify-center"
              >
                Joil Co., Ltd.
              </Link>
            </button>
          </li>
        </ul>
        {factory == 0 && <EN_BONSA />}
        {factory == 1 && <EN_YEONAM />}
        {factory == 2 && <EN_BASE />}
      </div>
      <FOOTER />
    </main>
  );
};
export default EN_MAP;
