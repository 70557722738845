import React, { useState } from "react";
import "swiper/swiper.scss";
import { mainfacility } from "./homedata";
import logo from "../../../assets/icon/logox2.png";
export const EN_Strength: React.FC = () => {
  const [index, setindex] = useState<number>(1);
  const indexHandler = (num: number) => {
    setindex(() => {
      return num;
    });
  };
  return (
    <div className="bg-main2 md:h-screen overflow-hidden pb-20 pt-20 md:pb-0 md:pt-40">
      <div className="2xl:max-w-screen-xl w-full h-full lg:overflow-visible relative m-auto text-white flex flex-wrap content-center justify-center">
        <div className="w-full h-full boxWrap flex flex-wrap content-center justify-center">
          <div className="st-inbox">
            <div className="st-innerbox bg-main1 flex flex-wrap content-center justify-center">
              <img src={logo} alt="조일공업" className="w-2/3" />
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/management#continue"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center"
              >
                <span className="font-bold text-white text-3xl sm:text-4xl sm:leading-normal leading-normal text-center">
                  Sustainable
                  <br />
                  management
                </span>
              </a>
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/management#safe"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center"
              >
                <span className="font-bold text-white text-3xl sm:text-4xl sm:leading-normal leading-normal text-center keep-all">
                  safety management activity
                </span>
              </a>
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/management#environment"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center"
              >
                <span className="font-bold text-white text-3xl sm:text-4xl sm:leading-normal leading-normal text-center keep-all">
                  environment management activity
                </span>
              </a>
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/line#robot"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center"
              >
                <span className="font-bold text-white text-3xl sm:text-4xl sm:leading-normal leading-normal text-center keep-all">
                  robot automated production line
                </span>
              </a>
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/line#autostack"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center"
              >
                <span className="font-bold text-white text-3xl sm:text-4xl sm:leading-normal leading-normal text-center keep-all">
                  mold automated loading storage
                </span>
              </a>
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/line#amount"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center"
              >
                <span className="font-bold text-white text-3xl sm:text-4xl sm:leading-normal leading-normal text-center keep-all">
                  establishing large-scale production network
                </span>
              </a>
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/line#smart"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center"
              >
                <span className="font-bold text-white text-3xl sm:text-4xl sm:leading-normal leading-normal text-center">
                  smart mobility
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
