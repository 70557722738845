import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import SwiperCore from "swiper/core";
import "swiper/swiper.scss";
import back from "../../../assets/icon/backArrow.png";
import { mainfacility } from "./homedata";
SwiperCore.use([Navigation, Pagination, Autoplay]);
export const EN_Facility: React.FC = () => {
  const [index, setindex] = useState<number>(1);
  const indexHandler = (num: number) => {
    setindex(() => {
      return num;
    });
  };
  return (
    <div className="bg-main2 lg:h-screen overflow-hidden pb-20 pt-20 lg:pb-0 lg:pt-40">
      <div className="2xl:max-w-screen-xl w-full h-full lg:overflow-visible relative m-auto text-white">
        <Swiper
          spaceBetween={0}
          loopAdditionalSlides={1}
          slidesPerView={1}
          speed={400}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          onSlideChange={(swiper) => {
            indexHandler(swiper.realIndex);
            let notnext = document.getElementsByClassName("swiper-slide");
            for (let i = 0; i < notnext.length; i++) {
              if (notnext[i].classList.contains("swiper-slide-next")) {
                notnext[i].classList.add("shade");
              } else {
                notnext[i].classList.remove("shade");
              }
            }
          }}
          loop={true}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          className={`facilS relative h-full`}
        >
          <button className="swiper-button-prev f-button-l w-28 h-28 lg:flex flex-wrap content-center justify-center hidden bg-main1">
            <img src={back} alt="priv" />
          </button>
          <button className="swiper-button-next f-button-r w-28 h-28 2xl:hidden lg:flex hidden flex-wrap content-center justify-center">
            <img src={back} alt="next" className="transform rotate-180" />
          </button>
          <button className="swiper-button-next f-button-rb 2xl:block hidden"></button>
          <div className="swiper-pagination absolute -bottom-10 z-10 lg:hidden block w-full text-center"></div>
          {mainfacility.map((obj) => {
            return (
              <SwiperSlide
                className={` lg:flex flex-wrap flex-col content-center justify-center`}
                key={obj.numbering}
              >
                <div className="flex content-center relative w-full px-16 sm:px-32 lg:flex-row flex-col">
                  <div className="textbox lg:w-1/2 block lg:text-left text-center lg:flex lg:flex-col lg:space-y-12 relative pb-5 lg:pb-0">
                    <p className="number lg:py-0 pb-3  lg:text-6xl xl:text-7xl sm:text-5xl text-4xl font-bold lg:block sm:inline-block pr-0 sm:pr-5 lg:leading-normal xl:leading-normal">
                      0{obj.numbering}
                    </p>
                    <p className="title lg:py-0  pb-3 lg:text-6xl xl:text-7xl sm:text-5xl text-4xl font-bold lg:block sm:inline-block pr-0 sm:pr-10 lg:leading-normal xl:leading-normal">
                      {obj.name}
                    </p>
                    <p className="lg:text-right  lg:pr-24 lg:pt-12 xl:pt-28 moveto lg:absolute bottom-0 right-0">
                      <a
                        href={obj.link}
                        className="capitalize lg:my-0 my-5 text-3xl border-white border w-60 h-16 lg:w-72 lg:h-24 inline-flex flex-wrap content-center justify-center "
                      >
                        detail
                      </a>
                    </p>
                  </div>
                  <div className="relative lg:pl-20 max-w-lg sm:max-w-xl md:max-w-none w-full md:w-1/2 m-auto lg:m-0">
                    <div
                      className={`rotatedText hidden ${
                        index == obj.numbering - 1 ? "lg:block" : ""
                      }`}
                    >
                      business area
                    </div>
                    <img
                      src={obj.image}
                      alt="picture of failities"
                      className="f-photo m-auto"
                    />
                  </div>
                </div>
                <p className="bottomText lg:block hidden text-border-white text-right leading-normal px-44 pt-5 text-7xl">
                  {obj.name}
                </p>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
