import mov1 from "../assets/images/business/part/01/con_MOVG01.jpg";
import mov2 from "../assets/images/business/part/01/con_MOVG02.jpg";
import mov3 from "../assets/images/business/part/01/con_MOVG03.jpg";
import mov4 from "../assets/images/business/part/01/con_MOVG04.jpg";
import mov5 from "../assets/images/business/part/01/con_MOVG05.jpg";
import mov6 from "../assets/images/business/part/01/con_MOVG06.jpg";
import mov7 from "../assets/images/business/part/01/con_MOVG07.jpg";
import mov8 from "../assets/images/business/part/01/con_MOVG08.jpg";
import mov9 from "../assets/images/business/part/01/con_MOVG09.jpg";
import mov10 from "../assets/images/business/part/01/con_MOVG10.jpg";
import body1 from "../assets/images/business/part/02/con_body01.jpg";
import body2 from "../assets/images/business/part/02/con_body02.jpg";
import body3 from "../assets/images/business/part/02/con_body03.jpg";
import body4 from "../assets/images/business/part/02/con_body04.jpg";
import chassis1 from "../assets/images/business/part/03/con_chassis01.jpg";
import chassis2 from "../assets/images/business/part/03/con_chassis02.jpg";
import chassis3 from "../assets/images/business/part/03/con_chassis03.jpg";
import chassis4 from "../assets/images/business/part/03/con_chassis04.jpg";
import trim1 from "../assets/images/business/part/04/con_trim01.jpg";
import trim2 from "../assets/images/business/part/04/con_trim02.jpg";
import trim3 from "../assets/images/business/part/04/con_trim03.jpg";
import fr1 from "../assets/images/business/part/05/con_frEND01.jpg";
import fr2 from "../assets/images/business/part/05/con_frEND02.jpg";
import fr3 from "../assets/images/business/part/05/con_frEND03.jpg";

export interface IProductProps {
  code: string;
  name: string;
  path: string;
}

export const listMovingProduct: IProductProps[] = [
  {
    code: "1-1",
    name: "BEAM ASSY-DR RENIF",
    path: mov1,
  },
  {
    code: "1-2",
    name: "MEMBER DR",
    path: mov2,
  },
  {
    code: "1-3",
    name: "RENIF DR HINGE FACE",
    path: mov3,
  },
  {
    code: "1-4",
    name: "RAIL & STRIKER ASSY-HOOD",
    path: mov4,
  },
  {
    code: "1-5",
    name: "RAIL-DR BELT OTR",
    path: mov5,
  },
  {
    code: "1-6",
    name: "RENIF ASSY-T/GATE G/BPR",
    path: mov6,
  },
  {
    code: "1-7",
    name: "RENIF ASSY-T/GATE SIDE LFTR",
    path: mov7,
  },
  {
    code: "1-8",
    name: "RENIF ASSY-T/GATE HING",
    path: mov8,
  },
  {
    code: "1-9",
    name: "RENIF ASSY-T/GATE WIPER MTR",
    path: mov9,
  },
  {
    code: "1-10",
    name: "RENIF-T/GATE LATCH",
    path: mov10,
  },
];
export const listBodyProduct: IProductProps[] = [
  {
    code: "2-1",
    name: "RAIL ASSY-ROOF RR",
    path: body1,
  },
  {
    code: "2-2",
    name: "RAIL ASSY-ROOF FR",
    path: body2,
  },
  {
    code: "2-3",
    name: "RAIL-ROOF CTR",
    path: body3,
  },
  {
    code: "2-4",
    name: "HOUSING ASSY-FUEL FILLER",
    path: body4,
  },
];
export const listChassisProduct: IProductProps[] = [
  {
    code: "3-1",
    name: "CLAMP ASSY-BATTERY",
    path: chassis1,
  },
  {
    code: "3-2",
    name: "STAY",
    path: chassis2,
  },
  {
    code: "3-3",
    name: "STAY ASSY-CTR FLOOR TUNNEL RR",
    path: chassis3,
  },
  {
    code: "3-4",
    name: "COVER-FUEL PUMP A/S",
    path: chassis4,
  },
];
export const listTrimProduct: IProductProps[] = [
  {
    code: "4-1",
    name: "EXTN-COWL SIDE MTG",
    path: trim1,
  },
  {
    code: "4-2",
    name: "ASSIST HDL MTG",
    path: trim2,
  },
  {
    code: "4-3",
    name: "BRKT-PANORAMAROOF SIDE",
    path: trim3,
  },
];
export const listFrProduct: IProductProps[] = [
  {
    code: "5-1",
    name: "MBR ASSY-RAD SUPT UPR",
    path: fr1,
  },
  {
    code: "5-2",
    name: "MBR ASSY-RAD SUPT UPR",
    path: fr2,
  },
  {
    code: "5-3",
    name: "MBR ASSY-RAD SUPT UPR",
    path: fr3,
  },
];
