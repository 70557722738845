import React from "react";
import shape from "../../../assets/icon/Shape.png";

import { Fade } from "react-awesome-reveal";
import { listCertification } from "../../../constants/certification_constant";

export const Certification = () => {
  return (
    <div className="max-w-screen-xl w-full px-8 pb-64 pt-52 m-auto">
      <div className="title_logo">
        <img src={shape} alt="손을 악수하는 모습의 아이콘" className="m-auto" />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-5xl sm:text-6xl font-bold text-center pb-52">
        <span className="leading-normal">인증현황</span>
      </h3>
      <div className="wrap flex flex-wrap justify-between gap-y-24">
        <Fade className="license" triggerOnce={true}>
          {listCertification.map((certification, index) => {
            return (
              <div key={`certification-kr-component-${index}`}>
                <p className="text-center text-3xl md:text-4xl lg:text-5xl text-main1 sm:h-24">{`${certification.name}`}</p>
                <div className="bg-gray-300 w-36 h-2 m-auto my-10"></div>
                <div className="w-full paper">
                  <img src={certification.url} alt={`${certification.name}`} className="absolute w-full left-0 top-0 border" />
                </div>
              </div>
            );
          })}
        </Fade>
      </div>
    </div>
  );
};
