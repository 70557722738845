import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import shape from "../../../assets/icon/Shape.png";
import { listHistory } from "../../../constants/history_constant";

export const History = () => {
  return (
    <div className="max-w-screen-lg w-full px-8 pb-32 sm:pb-64 pt-48 m-auto">
      <div className="title_logo">
        <img src={shape} alt="손을 악수하는 모습의 아이콘" className="m-auto" />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-5xl sm:text-6xl font-bold text-center pb-48">
        <span className="leading-normal">회사연혁</span>
      </h3>
      <div className="mt-32">
        <ul className="tree m-auto my-0">
          <li className="h-64 relative">
            <div className="pillar absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="left-1/2 transform -translate-x-1/2 bg-gray-300 w-4 h-4 absolute -top-3 rounded-full"></div>
          </li>
          {listHistory.map((history, index) => {
            return (
              <li className="h-72 relative" key={`history-kr-component-${index}`}>
                <Fade>
                  <div className="his-point w-7 h-7 absolute -top-3 rounded-full"></div>
                  <Fade delay={200} triggerOnce={true} direction={history.direction}>
                    <div className="branch h-full relative">
                      <div className="his-textbox w-full h-full absolute">
                        <p className="text-main1 text-3xl sm:text-5xl leading-normal sm:leading-normal">{`${history.years}`}</p>
                        <p className="text-main1 whitespace-pre-line text-2xl sm:text-4xl leading-normal sm:leading-normal keep-all">
                          <span className="text-logo">{`${history.textLogo}`}</span> {`${history.text}`}
                        </p>
                      </div>
                    </div>
                  </Fade>
                </Fade>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
