import React, { useState } from "react";
import { Link } from "react-router-dom";
import { divide, url } from "../../../apollo";
import { FOOTER } from "../../../layout/kor/footer";
import { SUBHEADER } from "../../../layout/kor/subheader";
import { useReactiveVar } from "@apollo/client";
import { Benefit } from "../../../parts/kor/recruit/benefit";
import { Hrsystem } from "../../../parts/kor/recruit/hrsystem";
import { Work } from "../../../parts/kor/recruit/work";
import { Talent } from "../../../parts/kor/recruit/talent";
import shape from "../../../assets/icon/human.png";
export const HRSYSTEM = () => {
  const pathname = useReactiveVar(url);
  let numbering = 0;
  if (divide(pathname).subValue == "인재상") {
    numbering = 0;
  } else if (divide(pathname).subValue == "인사정책") {
    numbering = 1;
  } else if (divide(pathname).subValue == "직무소개") {
    numbering = 2;
  } else if (divide(pathname).subValue == "보상제도") {
    numbering = 3;
  }
  const [factory, setfactory] = useState<number>(numbering);
  const factoryHandler = (num: number) => {
    setfactory(() => {
      return num;
    });
  };
  return (
    <main id="content">
      <SUBHEADER />
      <div
        id="recruit"
        className="max-w-screen-xl m-auto pb-32 sm:pb-64 pt-20 sm:pt-52"
      >
        <div className="title_logo">
          <img
            src={shape}
            alt="사람이 의자에 앉아있는 모습"
            className="m-auto"
          />
          <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
        </div>
        <h3 className="text-5xl lg:text-6xl font-bold text-center pb-32 sm:pb-52">
          <span className="leading-normal">인재채용</span>
        </h3>
        <ul className="recruit_border">
          <li
            className={`box-border text-4xl inline-block w-1/2 sm:w-1/4 h-28 ${
              factory == 0 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(0)}>
              <Link
                to="/recruit/talent"
                className="w-full h-full flex flex-wrap content-center justify-center"
              >
                인재상
              </Link>
            </button>
          </li>
          <li
            className={`box-border text-4xl inline-block w-1/2 sm:w-1/4 h-28 ${
              factory == 1 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(1)}>
              <Link
                to="/recruit/rule"
                className="w-full h-full flex flex-wrap content-center justify-center"
              >
                인사정책
              </Link>
            </button>
          </li>
          <li
            className={`box-border text-4xl inline-block w-1/2 sm:w-1/4 h-28 ${
              factory == 2 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(2)}>
              <Link
                to="/recruit/work"
                className=" w-full h-full flex flex-wrap content-center justify-center"
              >
                직무소개
              </Link>
            </button>
          </li>
          <li
            className={`box-border text-4xl inline-block w-1/2 sm:w-1/4 h-28 ${
              factory == 3 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(3)}>
              <Link
                to="/recruit/benefit"
                className=" w-full h-full flex flex-wrap content-center justify-center"
              >
                보상정책
              </Link>
            </button>
          </li>
        </ul>
        {factory == 0 && <Talent />}
        {factory == 1 && <Hrsystem />}
        {factory == 2 && <Work />}
        {factory == 3 && <Benefit />}
      </div>
      <FOOTER />
    </main>
  );
};
