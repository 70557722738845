import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import * as React from "react";
import { HOME } from "../page/kor/home";
import COMMENT from "../page/kor/intro/intro_comment";
import PHILOSOPHY from "../page/kor/intro/intro_philosophy";
import { IDEOLOGY } from "../page/kor/intro/intro_ideology";
import MAP from "../page/kor/map";
import { HISTORY } from "../page/kor/intro/intro_history";
import { CERTIFICATION } from "../page/kor/intro/intro_certification";
import { ORGANIZATION } from "../page/kor/intro/intro_organization";
import { MANAGEMENT } from "../page/kor/business/busi_management";
import { LINE } from "../page/kor/business/busi_line";
import { PRODUCTS } from "../page/kor/business/busi_products";
import { FACILITY } from "../page/kor/business/busi_facility";
import { HRSYSTEM } from "../page/kor/recruit/recruit_hrsystem";
import { ANNOUNCEMENT } from "../page/kor/recruit/recruit_announce";
// eng
import { EN_HOME } from "../page/eng/home";
import EN_COMMENT from "../page/eng/intro/intro_comment";
import EN_PHILOSOPHY from "../page/eng/intro/intro_philosophy";
import { EN_IDEOLOGY } from "../page/eng/intro/intro_ideology";
import EN_MAP from "../page/eng/map";
import { EN_HISTORY } from "../page/eng/intro/intro_history";
import { EN_CERTIFICATION } from "../page/eng/intro/intro_certification";
import { EN_ORGANIZATION } from "../page/eng/intro/intro_organization";
import { EN_MANAGEMENT } from "../page/eng/business/busi_management";
import { EN_LINE } from "../page/eng/business/busi_line";
import { EN_PRODUCTS } from "../page/eng/business/busi_products";
import { EN_FACILITY } from "../page/eng/business/busi_facility";
import { EN_HRSYSTEM } from "../page/eng/recruit/recruit_hrsystem";
import { EN_ANNOUNCEMENT } from "../page/eng/recruit/recruit_announce";
import { COMMITTE } from "../page/kor/esg/esg_committe";
import { GUIDELINES } from "../page/kor/esg/esg_guidelines";
import { PERFORMANCE } from "../page/kor/esg/esg_performance";
import { EN_COMMITTE } from "../page/eng/esg/esg_committe";
import { EN_GUIDELINES } from "../page/eng/esg/esg_guidelines";
import { EN_PERFORMANCE } from "../page/eng/esg/esg_performance";

const Root: React.FC = () => (
  <Router>
    <Switch>
      <Route path="/" exact component={HOME} />
      <Route path="/intro/comment" component={COMMENT} />
      <Route path="/intro/ideology" component={IDEOLOGY} />
      <Route path="/intro/philosophy" component={PHILOSOPHY} />
      <Route path="/intro/history" component={HISTORY} />
      <Route path="/intro/certification" component={CERTIFICATION} />
      <Route path="/intro/organization" component={ORGANIZATION} />
      <Redirect path="/intro" to="/intro/comment" />
      <Route path="/business/management" component={MANAGEMENT} />
      <Route path="/business/line" component={LINE} />
      <Route path="/business/products" component={PRODUCTS} />
      <Route path="/business/facility" component={FACILITY} />
      <Redirect path="/business" to="/business/management" />
      <Route path="/esg/committe" component={COMMITTE} />
      <Route path="/esg/guidelines" component={GUIDELINES} />
      <Route path="/esg/performance" component={PERFORMANCE} />
      <Redirect path="/esg" to="/esg/committe" />
      <Route path="/recruit/announce" component={ANNOUNCEMENT} />
      <Route path="/recruit/" component={HRSYSTEM} />
      <Redirect path="/recruit" to="/recruit/talent" />
      <Route path="/map" component={MAP} />

      {/* eng */}
      <Route path="/en/" exact component={EN_HOME} />
      <Route path="/en/intro/ideology" component={EN_IDEOLOGY} />
      <Route path="/en/intro/comment" component={EN_COMMENT} />
      <Route path="/en/intro/philosophy" component={EN_PHILOSOPHY} />
      <Route path="/en/intro/history" component={EN_HISTORY} />
      <Route path="/en/intro/certification" component={EN_CERTIFICATION} />
      <Route path="/en/intro/organization" component={EN_ORGANIZATION} />
      <Redirect path="/en/intro" to="/en/intro/comment" />
      <Route path="/en/business/management" component={EN_MANAGEMENT} />
      <Route path="/en/business/line" component={EN_LINE} />
      <Route path="/en/business/products" component={EN_PRODUCTS} />
      <Route path="/en/business/facility" component={EN_FACILITY} />
      <Redirect path="/en/business" to="/en/business/management" />
      <Route path="/en/esg/committe" component={EN_COMMITTE} />
      <Route path="/en/esg/guidelines" component={EN_GUIDELINES} />
      <Route path="/en/esg/performance" component={EN_PERFORMANCE} />
      <Redirect path="/en/esg" to="/en/esg/committe" />
      <Route path="/en/recruit/announce" component={EN_ANNOUNCEMENT} />
      <Route path="/en/recruit/" component={EN_HRSYSTEM} />
      <Redirect path="/en/recruit/" to="/en/recruit/talent" />
      <Route path="/en/map" component={EN_MAP} />
    </Switch>
  </Router>
);

export default Root;
