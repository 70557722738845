import "../layout.css";
import footer_logo from "../../assets/icon/logo-footer.png";
export const FOOTER = () => {
  return (
    <footer className="w-full h-300 box-border flex flex-wrap content-center  sm:justify-center flex-col bg-footer py-10 sm:py-0 sm:gap-y-10 gap-y-8 text-white px-10">
      <div className="text-center">
        <a href="/" className="footer_logo inline-block h-16">
          <img
            src={footer_logo}
            alt="조일공업 주식회사 로고"
            className="h-full inline-block"
          />
        </a>
      </div>
      <address className="text-xl sm:text-2xl not-italic text-left sm:text-center sm:space-y-0 space-y-2">
        <span className="afterbar block sm:inline">
          사업자번호 : 620-81-14739
        </span>
        <span className="afterbar block sm:inline">
          전화번호 : 052-280-0900
        </span>
        <span className="afterbar block sm:inline">
          메일 : joil_010@joilltd.co.kr
        </span>
        <span className="block sm:inline">
          주소 : 울산광역시 북구 효성로 43 (효문동)
        </span>
      </address>
      {/* <div className="fnb text-center text-2xl sm:space-x-10 sm:space-y-0 space-y-4">
        <a
          href="/intro/comment"
          className="hover:underline hover:text-red-700 sm:inline block"
        >
          회사소개
        </a>
        <a
          href="#"
          className="hover:underline hover:text-red-700 sm:inline block"
        >
          개인정보취급방침
        </a>
        <a
          href="#"
          className="hover:underline hover:text-red-700 sm:inline block"
        >
          서비스이용약관
        </a>
      </div> */}
      <small className="text-center text-xl sm:text-2xl">
        <span className="capitalize">copyright</span>&copy; 조일공업㈜.
        <span className="capitalize">all</span> rights reserved.
      </small>
    </footer>
  );
};
