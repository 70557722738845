import React, { useState } from "react";
import "swiper/swiper.scss";
import { mainfacility } from "./homedata";
import logo from "../../../assets/icon/logox2.png";
export const Strength: React.FC = () => {
  const [index, setindex] = useState<number>(1);
  const indexHandler = (num: number) => {
    setindex(() => {
      return num;
    });
  };
  return (
    <div className="bg-main2 md:h-screen overflow-hidden pb-20 pt-20 md:pb-0 md:pt-40">
      <div className="2xl:max-w-screen-xl w-full h-full lg:overflow-visible relative m-auto text-white flex flex-wrap content-center justify-center">
        <div className="w-full h-full boxWrap flex flex-wrap content-center justify-center">
          <div className="st-inbox">
            <div className="st-innerbox bg-main1 flex flex-wrap content-center justify-center">
              <img src={logo} alt="조일공업" className="w-2/3" />
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/management#continue"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center "
              >
                <span className="font-bold text-white text-2xl sm:text-4xl  text-center leading-normal sm:leading-normal">
                  지속
                  <br />
                  가능경영
                </span>
              </a>
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/management#safe"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center"
              >
                <span className="font-bold text-white text-2xl sm:text-4xl leading-normal sm:leading-normal text-center">
                  안전
                  <br />
                  경영활동
                </span>
              </a>
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/management#environment"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center"
              >
                <span className="font-bold text-white text-2xl sm:text-4xl leading-normal sm:leading-normal text-center">
                  환경
                  <br />
                  경영활동
                </span>
              </a>
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/line#robot"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center"
              >
                <span className="font-bold text-white text-2xl sm:text-4xl leading-normal sm:leading-normal text-center">
                  로봇 자동화
                  <br />
                  생산라인
                </span>
              </a>
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/line#autostack"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center"
              >
                <span className="font-bold text-white text-2xl sm:text-4xl leading-normal sm:leading-normal text-center">
                  금형 자동화
                  <br />
                  적재 창고
                </span>
              </a>
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/line#amount"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center"
              >
                <span className="font-bold text-white text-2xl sm:text-4xl leading-normal sm:leading-normal text-center">
                  대규모
                  <br />
                  생산네트워크
                  <br />
                  구축
                </span>
              </a>
            </div>
          </div>
          <div className="st-inbox">
            <div className="st-innerbox">
              <a
                href="/business/line#smart"
                className="absolute left-0 top-0 w-full h-full flex flex-wrap content-center justify-center"
              >
                <span className="font-bold text-white text-2xl sm:text-4xl leading-normal sm:leading-normal text-center">
                  스마트
                  <br />
                  모빌리티
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
