import React from "react";
import { FOOTER } from "../../../layout/kor/footer";
import { SUBHEADER } from "../../../layout/kor/subheader";
import { Line } from "../../../parts/kor/business/line";
export const LINE = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="business">
        <Line />
      </div>
      <FOOTER />
    </main>
  );
};
