import "../../css/home.css";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import video from "../../../assets/video/main.mp4";
import main from "../../../assets/images/home/main_back.png";
import "react-circular-progressbar/dist/styles.css";
export const Main = () => {
  const [percentage, setpercentage] = useState<number>(0);

  const percentageHandler = (child: number, mother: number) => {
    setpercentage(() => {
      return ((child + 0.1) * 100) / mother;
    });
  };
  return (
    <div id="main" className="w-full h-screen">
      <div className="w-full h-full bg-black bg-opacity-20 flex flex-wrap content-end sm:content-center pb-32 sm:pb-0 relative">
        <video
          muted
          loop
          autoPlay
          playsInline
          className="w-full h-full absolute left-0 top-0 object-cover"
          onTimeUpdate={(e) => {
            let progress = e.currentTarget.currentTime;
            let duration = e.currentTarget.duration;
            percentageHandler(progress, duration);
          }}
        >
          <source src={video} />
        </video>
        <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-30"></div>
        <div className="w-5/6 m-auto flex flex-wrap justify-center sm:justify-between relative">
          <div className="text-white space-y-14">
            <h1 className="text-4xl uppercase font-bold sm:text-6xl">
              our vision for the future
            </h1>
            <div className="text-2xl sm:text-5xl">
              <p className="leading-tight">자동차 차체부품생산 일등 기업!</p>
              <p className="leading-tight">
                기업가치 향상을 통한 행복한 삶을 추구합니다.
              </p>
            </div>
          </div>
          <div className="flex flex-wrap content-center">
            <CircularProgressbar
              minValue={0}
              value={percentage}
              maxValue={100}
              strokeWidth={5}
              styles={buildStyles({
                pathTransition:
                  percentage < 1 ? "none" : "stroke-dashoffset 0.5s ease 0s",
                pathColor: "#BE2F10",
              })}
              className="w-40 hidden xl:block"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
