import React, { useState } from "react";
import { Link } from "react-router-dom";
import { divide, url, en_divide } from "../../../apollo";
import { FOOTER } from "../../../layout/eng/footer";
import { useReactiveVar } from "@apollo/client";
import { SUBHEADER } from "../../../layout/eng/subheader";
import { EN_Benefit } from "../../../parts/eng/recruit/benefit";
import { EN_Hrsystem } from "../../../parts/eng/recruit/hrsystem";
import { EN_Work } from "../../../parts/eng/recruit/work";
import { EN_Talent } from "../../../parts/eng/recruit/talent";
import shape from "../../../assets/icon/human.png";
import { Helmet } from "react-helmet";
export const EN_HRSYSTEM = () => {
  const pathname = useReactiveVar(url);
  let numbering = 0;
  if (en_divide(pathname).subValue == "talent") {
    numbering = 0;
  } else if (en_divide(pathname).subValue == "HR policy") {
    numbering = 1;
  } else if (en_divide(pathname).subValue == "Job introduction") {
    numbering = 2;
  } else if (en_divide(pathname).subValue == "benefits") {
    numbering = 3;
  }
  const [factory, setfactory] = useState<number>(numbering);
  const factoryHandler = (num: number) => {
    setfactory(() => {
      return num;
    });
  };
  return (
    <main id="content">
      <SUBHEADER />
      <div
        id="recruit"
        className="max-w-screen-xl m-auto pb-32 sm:pb-64 pt-20 sm:pt-52"
      >
        <div className="title_logo">
          <img
            src={shape}
            alt="icon of a person sit on chair"
            className="m-auto"
          />
          <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
        </div>
        <h3 className="text-5xl lg:text-6xl font-bold text-center pb-32 sm:pb-52">
          <span className="leading-normal">Careers</span>
        </h3>
        <ul className="recruit_border">
          <li
            className={`box-border text-4xl inline-block align-middle w-1/2 sm:w-1/4 sm:h-32 h-28 ${
              factory == 0 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(0)}>
              <Link
                to="/en/recruit/talent"
                className="w-full h-full flex flex-wrap content-center justify-center"
              >
                Talent
              </Link>
            </button>
          </li>
          <li
            className={`box-border text-4xl inline-block align-middle w-1/2 sm:w-1/4 sm:h-32 h-28 ${
              factory == 1 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(1)}>
              <Link
                to="/en/recruit/rule"
                className="w-full h-full flex flex-wrap content-center justify-center"
              >
                HR policy
              </Link>
            </button>
          </li>
          <li
            className={`box-border text-4xl inline-block align-middle w-1/2 sm:w-1/4 sm:h-32 h-28 ${
              factory == 2 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(2)}>
              <Link
                to="/en/recruit/work"
                className=" w-full h-full flex flex-wrap content-center justify-center"
              >
                Job introduction
              </Link>
            </button>
          </li>
          <li
            className={`box-border text-4xl inline-block align-middle w-1/2 sm:w-1/4 sm:h-32 h-28 ${
              factory == 3 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(3)}>
              <Link
                to="/en/recruit/benefit"
                className=" w-full h-full flex flex-wrap content-center justify-center"
              >
                Benefits
              </Link>
            </button>
          </li>
        </ul>
        {factory == 0 && <EN_Talent />}
        {factory == 1 && <EN_Hrsystem />}
        {factory == 2 && <EN_Work />}
        {factory == 3 && <EN_Benefit />}
      </div>
      <FOOTER />
    </main>
  );
};
