import React, { useState } from "react";
import "../../css/business.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import back from "../../../assets/icon/backArrow.png";
import shape from "../../../assets/icon/Frame.png";
import { facilityData } from "../../../constants/facility_constant";

export const EN_Facility = () => {
  document.body.classList.add("smooth");
  const [tab, settab] = useState<number>(0);
  const settabHandler = (num: number) => {
    settab(() => {
      return num;
    });
  };
  return (
    <div className="max-w-screen-xl m-auto pb-32 sm:pb-64 pt-20 sm:pt-52">
      <div className="title_logo">
        <img src={shape} alt="icon of robot arm is putting a stuff on line" className="m-auto" />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-5xl lg:text-6xl font-bold text-center pb-132">
        <span className="leading-normal">Facility status</span>
      </h3>
      <ul className="listborder facil-m-border">
        <li className={`box-border text-2xl md:text-4xl align-middle inline-block w-1/2 lg:w-1/3 h-32 sm:h-28 ${tab == 0 && "selectList"}`}>
          <button className="w-full h-full" onClick={() => settabHandler(0)}>
            <a href="#list" className="w-full h-full flex flex-wrap content-center justify-center">
              <p>
                <span className="capitalize">transfer automation </span>
                press line
              </p>
            </a>
          </button>
        </li>
        <li className={`box-border text-2xl md:text-4xl align-middle inline-block w-1/2 lg:w-1/3 h-32 sm:h-28 ${tab == 1 && "selectList"}`}>
          <button className="w-full h-full" onClick={() => settabHandler(1)}>
            <a href="#movg" className="w-full h-full flex flex-wrap content-center justify-center">
              <p>
                <span className="capitalize">automation </span>robotic press
              </p>
            </a>
          </button>
        </li>
        <li className={`box-border text-2xl md:text-4xl align-middle inline-block w-1/2 lg:w-1/3 h-32 sm:h-28 ${tab == 2 && "selectList"}`}>
          <button className="w-full h-full" onClick={() => settabHandler(2)}>
            <a href="#body" className="w-full h-full flex flex-wrap content-center justify-center">
              <p>
                <span className="capitalize">welding </span> line
              </p>
            </a>
          </button>
        </li>
        <li className={`box-border text-2xl md:text-4xl align-middle inline-block w-1/2 h-32 sm:h-28 ${tab == 3 && "selectList"}`}>
          <button className="w-full h-full" onClick={() => settabHandler(3)}>
            <a href="#chassis" className="w-full h-full flex flex-wrap content-center justify-center">
              <p>
                <span className="capitalize">automatic welding </span> of H/W
              </p>
            </a>
          </button>
        </li>
        <li className={`box-border text-2xl md:text-4xl align-middle inline-block w-1/2 h-32 sm:h-28 ${tab == 4 && "selectList"}`}>
          <button className="w-full h-full" onClick={() => settabHandler(4)}>
            <a href="#trim" className="w-full h-full flex flex-wrap content-center justify-center">
              <p>
                <span className="capitalize">automatic </span>mold storage warehouse
              </p>
            </a>
          </button>
        </li>
      </ul>
      <div className="max-w-screen-xl overflow-hidden m-auto pt-24">
        <Swiper
          spaceBetween={0}
          loopAdditionalSlides={1}
          slidesPerView={1}
          speed={400}
          //   autoplay={{
          //     delay: 4000,
          //     disableOnInteraction: false,
          //   }}
          onSlideChange={(swiper) => {}}
          loop={false}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          }}
          className={`facilS relative h-full`}
        >
          <button className="swiper-button-prev w-28 h-28 absolute left-0 top-1/2 transform -translate-y-1/2 hidden lg:flex flex-wrap content-center justify-center bg-main1 z-10">
            <img src={back} alt="뒤로가기" />
          </button>
          <button className="swiper-button-next w-28 h-28 absolute right-0 top-1/2 transform -translate-y-1/2 hidden lg:flex flex-wrap content-center justify-center bg-main1 z-10">
            <img src={back} alt="앞으로 가기" className="transform rotate-180" />
          </button>
          <div className="swiper-pagination absolute bottom-5 z-10 block w-full text-center"></div>
          {facilityData[tab].video.length > 0 &&
            facilityData[tab].video.map((obj) => (
              <SwiperSlide className={`flex flex-wrap flex-col content-center justify-center`}>
                <div className="max-w-screen-lg m-auto">
                  <video src={obj} autoPlay loop muted className="w-full"></video>
                </div>
              </SwiperSlide>
            ))}
          {facilityData[tab].images.map((obj) => (
            <SwiperSlide className={`flex flex-wrap flex-col content-center justify-center`}>
              <div className="max-w-screen-lg m-auto">
                <img src={obj} alt="조일공업 프레스 기계 이미지" className="w-full" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="pt-24 max-w-screen-lg m-auto px-5">
          <p className="text-main1 text-2xl md:text-4xl leading-normal hidden"></p>
        </div>
      </div>
    </div>
  );
};
