import React from "react";
import grade from "../../../assets/images/recruit/en_grade.png";
import { Fade } from "react-awesome-reveal";
export const EN_Hrsystem = () => {
  return (
    <div className="pt-24 pb-32 sm:pb-64 px-5">
      <h4 className="text-4xl sm:text-5xl font-bold text-center">
        Personnel policy
      </h4>
      <div className="w-36 h-1 bg-gray-500 m-auto mt-12 mb-24"></div>
      <div className="hrsystem">
        <div className="pt-24 max-w-screen-lg m-auto my-0">
          <Fade delay={300} triggerOnce={true}>
            <img
              src={grade}
              alt="job title. 
              Staff, Administrative manager, Assistant manager, Manager ,Deputy general manager, Executive manager"
            />
          </Fade>
        </div>
        <Fade delay={300} triggerOnce={true}>
          <p className="pt-24 sm:pt-48 text-4xl md:text-5xl text-center keep-all">
            Pursuing talent-focused company value.
          </p>
          <p className="pt-24 sm:pt-48 text-4xl text-center leading-normal">
            Joil Co., Ltd. pursues a company culture that focuses on and
            respects talents under the human-based management ideology.
            <br />
            Thorough and fair evaluation based on individual abilities and work
            performance is utilized for an objective personnel policy.
          </p>
          <div className="pt-24 sm:pt-48 grid grid-col-1 md:grid-cols-2 gap-x-11 gap-y-24">
            <div>
              <div className="bg-main1 text-white text-4xl md:text-5xl font-bold text-center py-5 leading-normal">
                Regular promotion
              </div>
              <div className="h-3 bg-gray-300"></div>
              <div className="px-12  py-5 h-80 leading-normal border border-gray-400 border-t-0 flex flex-wrap content-center justify-center ">
                <p className="text-2xl sm:text-3xl text-main1 tracking-tight">
                  Once a year (Dec), for those exceeding the promotion period
                  limit, the person of authority grants promotions if work
                  performance is outstanding and the individual is deemed to be
                  able to fulfill higher positions.
                </p>
              </div>
            </div>
            <div>
              <div className="bg-main1 text-white text-4xl md:text-5xl font-bold text-center py-5 leading-normal">
                Special promotion
              </div>
              <div className="h-3 bg-gray-300"></div>
              <div className="px-12  py-5 h-80 leading-normal border border-gray-400 border-t-0 flex flex-wrap content-center justify-center ">
                <p className="text-2xl sm:text-3xl text-main1 tracking-tight">
                  The person of authority can make special promotions based on
                  the need of the company.
                </p>
              </div>
            </div>
            <div>
              <div className="bg-main1 text-white text-4xl md:text-5xl font-bold text-center py-5 leading-normal">
                Salary system
              </div>
              <div className="h-3 bg-gray-300"></div>
              <div className="px-12  py-5 h-80 leading-normal border border-gray-400 border-t-0 flex flex-wrap content-center justify-center ">
                <p className="text-2xl sm:text-3xl text-main1 tracking-tight">
                  Office : Annual salary system (Differences every year based on
                  performance and capabilities)
                  <br />
                  Production : Monthly or hourly pay
                </p>
              </div>
            </div>
            <div>
              <div className="bg-main1 text-white text-4xl md:text-5xl font-bold text-center py-5 leading-normal">
                Welfare
              </div>
              <div className="h-3 bg-gray-300"></div>
              <div className="px-12  py-5 h-80 leading-normal border border-gray-400 border-t-0 flex flex-wrap content-center justify-center ">
                <p className="text-2xl  sm:text-3xl text-main1 tracking-tight">
                  Kindergarten, elementary/middle/high school, and university
                  tuition support medical checkup support every year other
                  welfare support based on company regulations
                </p>
              </div>
            </div>
            <div>
              <div className="bg-main1 text-white text-4xl md:text-5xl font-bold text-center py-5 leading-normal">
                Others
              </div>
              <div className="h-3 bg-gray-300"></div>
              <div className="px-12  py-5 h-80 leading-normal border border-gray-400 border-t-0 flex flex-wrap content-center justify-center ">
                <p className="text-2xl  sm:text-3xl text-main1 tracking-tight">
                  For experienced recruits, the person of authority grants
                  positions and follows the promotion period limits in the
                  future based on company bylaws.
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};
