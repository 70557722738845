import React, { useState } from "react";
import ReactPageScroller from "react-page-scroller";
import "../page.css";
import { FOOTER } from "../../layout/eng/footer";
import { HEADER } from "../../layout/eng/header";
import { EN_Main } from "../../parts/eng/home/main";
import { EN_Facility } from "../../parts/eng/home/facilities";
import { EN_Product } from "../../parts/eng/home/products";
import { EN_Strength } from "../../parts/eng/home/strength";
import { Helmet } from "react-helmet";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
export const EN_HOME = () => {
  const [lastscroll, setscroll] = useState<boolean>(false);
  const banscrollHandler = () => {
    setscroll(() => {
      return false;
    });
  };
  const okscrollHandler = () => {
    document.body.classList.add("noScrollBar");
    setscroll(() => {
      return true;
    });
  };
  return isBrowser ? (
    <main id="content" className={`pc ${lastscroll ? "" : "banScroll"}`}>
      <HEADER />
      <ReactPageScroller
        animationTimer={500}
        pageOnChange={function scrollcontrol(number) {
          if (number == 2) {
            var slide = document
              .getElementsByClassName("prodS")[0]
              .getElementsByClassName("swiper-slide");
            for (let i = 0; i < slide.length; i++) {
              if (slide[i].classList.contains("swiper-slide-active")) {
                slide[i].getElementsByTagName("video")[0].play();
              } else {
                slide[i].getElementsByTagName("video")[0].currentTime = 0;
                slide[i].getElementsByTagName("video")[0].pause();
              }
            }
          } else if (number == 3) {
            okscrollHandler();
          } else {
            banscrollHandler();
          }
        }}
      >
        <EN_Main />
        <EN_Facility />
        <EN_Product />
        <EN_Strength />
      </ReactPageScroller>
      <FOOTER />
    </main>
  ) : (
    <main id="content" className="mobile">
      <HEADER />
      <EN_Main />
      <EN_Facility />
      <EN_Product />
      <EN_Strength />

      <FOOTER />
    </main>
  );
};
