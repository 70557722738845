import React from "react";
import { FOOTER } from "../../../layout/eng/footer";
import { SUBHEADER } from "../../../layout/eng/subheader";
import organ from "../../../assets/images/esg/org.png";
export const EN_COMMITTE = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="esg" className="max-w-screen-lg w-full px-8 pb-32 sm:pb-64 pt-48 m-auto">
        <img src={organ} className="w-full"/>
      </div>
      <FOOTER />
    </main>
  );
};
