import React from "react";
import { FOOTER } from "../../../layout/kor/footer";
import { SUBHEADER } from "../../../layout/kor/subheader";
import shape from "../../../assets/icon/human.png";
import { Announce } from "../../../parts/kor/recruit/announce";
export const ANNOUNCEMENT = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="recruit" className="max-w-screen-xl m-auto pt-20 sm:pt-52 pb-64">
        <div className="title_logo">
          <img
            src={shape}
            alt="사람이 의자에 앉아있는 모습"
            className="m-auto"
          />
          <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
        </div>
        <h3 className="text-5xl lg:text-6xl font-bold text-center pb-28 sm:pb-52">
          <span className="leading-normal">채용안내</span>
        </h3>
        <Announce />
      </div>
      <FOOTER />
    </main>
  );
};
