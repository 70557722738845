import React from "react";
import shape from "../../../assets/icon/Shape.png";
import organ from "../../../assets/images/intro/en_organization.png";
import organ_mobile from "../../../assets/images/intro/en_mobile_organization.png";
export const EN_Organization = () => {
  return (
    <div className="max-w-screen-xl w-full px-8 pb-32 md:pb-64 pt-20 md:pt-52 m-auto">
      <div className="title_logo">
        <img src={shape} alt="hand shake icon" className="m-auto" />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-5xl sm:text-6xl font-bold text-center pb-52">
        <span className="leading-normal">Organization</span>
      </h3>
      <div className="wrap">
        <img
          src={organ}
          alt="organization chaet. CEO ,General manager,  annex research lab. and there are devided 2 groups, one is production business department that have production/production technology team and resources team , quility assurance team , quality management team, and sales team. the other is development business department that have development team with mold team , management team and production cost team."
          className="hidden sm:block"
        />
        <img
          src={organ_mobile}
          alt="organization chaet. CEO , General manage,  annex research lab. and there are devided 2 groups, one is production business department that have production/production technology team and resources team , quility assurance team , quality management team, and sales team. the other is development business department that have development team with mold team , management team and production cost team."
          className="sm:hidden block"
        />
      </div>
    </div>
  );
};
