import React from "react";
import shape from "../../../assets/icon/Shape.png";
import organ from "../../../assets/images/intro/organization.png";
import organ_mobile from "../../../assets/images/intro/mobile_organization.png";
export const Organization = () => {
  return (
    <div className="max-w-screen-xl w-full px-8 pb-32 md:pb-64 pt-20 md:pt-52 m-auto">
      <div className="title_logo">
        <img src={shape} alt="손을 악수하는 모습의 아이콘" className="m-auto" />
        <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
      </div>
      <h3 className="text-5xl sm:text-6xl font-bold text-center pb-52">
        <span className="leading-normal">조직도</span>
      </h3>
      <div className="wrap">
        <img
          src={organ}
          alt="조일공업의 조직도. 대표 이사 밑으로 총괄이 있고 , 별개로 부설연구소가 있음. 이후 생산사업부와 개발사업부가 나눠진다. 생산사업부엔 생산관리와 생산기술을 담당하는 생산/생기팀, 원/부자재와 FTA원산지를 담당하는 자재팀, OEM관리와 KD관리를 하는 영업팀, KD/양산품질과 신차품질을 담당하는 품질보증팀, 협력사지도와 SQ운영관리를 담당하는 품질경영팀이 있다. 개발사업부엔 개발관리를 담당하는 개발팀과 원가팀, 관리팀이 있다. 별개로 금형실이 있음. "
          className="hidden sm:block"
        />
        <img
          src={organ_mobile}
          alt="조일공업의 조직도. 대표 이사 밑으로 총괄이 있고 , 별개로 부설연구소가 있음. 이후 생산사업부와 개발사업부가 나눠진다. 생산사업부엔 생산관리와 생산기술을 담당하는 생산/생기팀, 원/부자재와 FTA원산지를 담당하는 자재팀, OEM관리와 KD관리를 하는 영업팀, KD/양산품질과 신차품질을 담당하는 품질보증팀, 협력사지도와 SQ운영관리를 담당하는 품질경영팀이 있다. 개발사업부엔 개발관리를 담당하는 개발팀과 원가팀, 관리팀이 있다. 별개로 금형실이 있음. "
          className="sm:hidden block"
        />
      </div>
    </div>
  );
};
