import React from "react";
import { Fade } from "react-awesome-reveal";
import resource from "../../../assets/icon/HumanResoure.png";
import success from "../../../assets/icon/Success.png";
export const EN_Benefit = () => {
  return (
    <div className="pt-24 px-4">
      <h4 className="text-4xl sm:text-5xl font-bold text-center capitalize">
        benefit
      </h4>
      <div className="w-36 h-1 bg-gray-500 m-auto mt-12 mb-24"></div>
      <div className="benefit pt-10 sm:pt-24">
        <Fade triggerOnce={true}>
          <div className="en-benefit-height recruit-benefit-grid">
            <div className="bg-main1 flex flex-wrap flex-col content-center justify-center">
              <img
                src={resource}
                alt="benefit icon. three person on big hands"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="text-white font-bold text-3xl sm:text-4xl mt-20  capitalize">
                benefit
              </p>
            </div>
            <div className="py-5 text-main1 border border-gray-500 flex flex-wrap flex-col content-center justify-center">
              <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal tracking-tight">
                <p className="font-bold">- Basic salary</p>
                <p className="pl-6">
                  Base pay + bonus (varying increase rates based on performance
                  evaluation)
                </p>
              </div>
              <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal tracking-tight">
                <p className="font-bold">- Achievement bonus</p>
                <p className="pl-6">
                  Differential payment based on capability and achievement
                  evaluation (annual salary system)
                </p>
              </div>
              <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal tracking-tight">
                <p className="font-bold">- Outstanding employee award</p>
                <p className="pl-6">
                  Select outstanding employee for each area during the year and
                  provide awards based on performance
                </p>
              </div>
              <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal tracking-tight">
                <p className="font-bold">- Group work performance award</p>
                <p className="pl-6">
                  Select a division (team) with outstanding performance every
                  month and provide awards
                </p>
              </div>
            </div>
          </div>
          <div className="mt-24 sm:mt-48 benefit-height recruit-benefit-grid">
            <div className="bg-main1 flex flex-wrap flex-col content-center justify-center">
              <img
                src={success}
                alt="welfare icon. a person is on top of stairs"
                className="w-1/2 sm:w-1/4 m-auto my-0"
              />
              <p className="text-white font-bold text-3xl sm:text-4xl mt-20">
                Welfare
              </p>
            </div>
            <div className="text-main1 border border-gray-500 flex flex-wrap flex-col content-center justify-center">
              <div className="text-2xl sm:text-3xl w-full px-6 sm:px-32 leading-normal tracking-tight">
                <p className="font-bold">- Other welfare</p>
                <ul className="pl-10 sm:pl-20">
                  <li className="list-disc">
                    Tuition support to employee’s children (kindergarten,
                    elementary/ middle/ high school, university)
                  </li>
                  <li className="list-disc">
                    Medical checkup and expenses support
                  </li>
                  <li className="list-disc">
                    Congratulatory/ condolence leave, expenditure/ goods support
                  </li>
                  <li className="list-disc">
                    Provide company housing for foreign and long-distance
                    workers
                  </li>
                  <li className="list-disc">
                    Housing purchase interest-free loan
                  </li>
                  <li className="list-disc">Host sports meets and picnics</li>
                  <li className="list-disc">Support company clubs</li>
                  <li className="list-disc">
                    Provide presents for holidays and birthdays
                  </li>
                  <li className="list-disc">
                    Hiring disabled people and other welfare based on company
                    bylaws
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};
