import first1 from "../assets/images/business/facility/01/facility_01_1.png";
import first2 from "../assets/images/business/facility/01/facility_01_2.png";
import first3 from "../assets/images/business/facility/01/facility_01_3.png";
import first4 from "../assets/images/business/facility/01/facility_01_4.png";
import first5 from "../assets/images/business/facility/01/facility_01_5.png";
import second1 from "../assets/images/business/facility/02/facility_02_1.png";
import second2 from "../assets/images/business/facility/02/facility_02_2.png";
import second3 from "../assets/images/business/facility/02/facility_02_3.png";
import second4 from "../assets/images/business/facility/02/facility_02_4.png";
import second5 from "../assets/images/business/facility/02/facility_02_5.png";
import second6 from "../assets/images/business/facility/02/facility_02_6.png";
import third1 from "../assets/images/business/facility/03/facility_03_1.png";
import third2 from "../assets/images/business/facility/03/facility_03_2.png";
import third3 from "../assets/images/business/facility/03/facility_03_3.png";
import third4 from "../assets/images/business/facility/03/facility_03_4.png";
import third5 from "../assets/images/business/facility/03/facility_03_5.png";
import fourth1 from "../assets/images/business/facility/04/facility_04_1.png";
import fourth2 from "../assets/images/business/facility/04/facility_04_2.png";
import fourth3 from "../assets/images/business/facility/04/facility_04_3.png";
import fourth4 from "../assets/images/business/facility/04/facility_04_4.png";
import fifth1 from "../assets/images/business/facility/05/facility_05_1.jpg";
import fifth2 from "../assets/images/business/facility/05/facility_05_2.jpg";
import fifth3 from "../assets/images/business/facility/05/facility_05_3.jpg";

import firstV1 from "../assets/video/facility/transfer1.mp4";
import firstV2 from "../assets/video/facility/transfer11.mp4";
import secondV from "../assets/video/facility/transfer2.mp4";
import fourthV from "../assets/video/facility/transfer4.mp4";

export const facilityData = [
  {
    video: [firstV1, firstV2],
    images: [first1, first2, first3, first4, first5],
  },
  {
    video: [secondV],
    images: [second1, second2, second3, second4, second5, second6],
  },
  {
    video: [],
    images: [third1, third2, third3, third4, third5],
  },
  {
    video: [fourthV],
    images: [fourth1, fourth2, fourth3, fourth4],
  },
  {
    video: [],
    images: [fifth1, fifth2, fifth3],
  },
];
