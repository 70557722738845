import facil1 from "../../../assets/images/home/facil1.png";
import facil2 from "../../../assets/images/home/facil2.png";
import facil3 from "../../../assets/images/home/facil3.png";
import facil4 from "../../../assets/images/home/facil4.png";
import facil5 from "../../../assets/images/home/facil5.png";
import iamge from "../../../assets/images/home/product.png";
import prdctVideo from "../../../assets/video/assemble.mp4";
import prdctVideo2 from "../../../assets/video/partsinfo.mp4";

// facility

export const mainfacility = [
  {
    numbering: 1,
    name: "Transfer Automation press line",
    desc: "",
    link: "/business/facility",
    image: facil1,
  },
  {
    numbering: 2,
    name: "Automation robotic press",
    desc: "",

    link: "/business/facility",
    image: facil2,
  },
  {
    numbering: 3,
    name: "Welding Line",
    desc: "",

    link: "/business/facility",
    image: facil3,
  },
  {
    numbering: 4,
    name: "Automatic welding of H/W",
    desc: "",

    link: "/business/facility",
    image: facil4,
  },
  {
    numbering: 5,
    name: "Automatic mold storage warehouse",
    desc: "",

    link: "/business/facility",
    image: facil5,
  },
];

// product
export const mainproduct = [
  {
    type: 2,
    numbering: 1,
    what: "video explaining parts of car",
    path: prdctVideo,
  },
  {
    type: 2,
    numbering: 2,
    what: "video explaining parts of car",
    path: prdctVideo2,
  },
];
