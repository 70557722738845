import React, { useState } from "react";
import { Link } from "react-router-dom";
import { divide, url } from "../../apollo";
import { FOOTER } from "../../layout/kor/footer";
import { SUBHEADER } from "../../layout/kor/subheader";
import { BASE } from "../../parts/kor/map/base";
import { BONSA } from "../../parts/kor/map/bonsa";
import { useReactiveVar } from "@apollo/client";
import { YEONAM } from "../../parts/kor/map/yeonam";
import shape from "../../assets/icon/car.png";
export const MAP = () => {
  const pathname = useReactiveVar(url);
  let numbering = 0;
  if (divide(pathname).subValue == "조일공업 본사") {
    numbering = 0;
  } else if (divide(pathname).subValue == "연암공장") {
    numbering = 1;
  } else if (divide(pathname).subValue == "(주)조일") {
    numbering = 2;
  }
  const [factory, setfactory] = useState<number>(numbering);
  const factoryHandler = (num: number) => {
    setfactory(() => {
      return num;
    });
  };

  return (
    <main id="content">
      <SUBHEADER />
      <div
        id="map"
        className="max-w-screen-xl m-auto pt-20 pb-32 sm:pb-64 sm:pt-52"
      >
        <div className="title_logo">
          <img src={shape} alt="자동차 아이콘" className="m-auto" />
          <div className="w-8 h-1 m-auto my-8 bg-gray-400"></div>
        </div>
        <h3 className="text-5xl lg:text-6xl font-bold text-center pb-28 sm:pb-52">
          <span className="leading-normal">오시는 길</span>
        </h3>
        <ul className="listborder map-m-border">
          <li
            className={`box-border text-2xl sm:text-3xl align-middle inline-block w-1/3 h-36 sm:h-28 ${
              factory == 0 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(0)}>
              <Link
                to="/map/base"
                className="w-full h-full flex flex-wrap content-center justify-center"
              >
                조일공업(주) 본사
              </Link>
            </button>
          </li>
          <li
            className={`box-border text-2xl sm:text-3xl align-middle inline-block w-1/3 h-36 sm:h-28 ${
              factory == 1 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(1)}>
              <Link
                to="/map/yeonam"
                className="w-full h-full flex flex-wrap content-center justify-center"
              >
                연암공장
              </Link>
            </button>
          </li>
          <li
            className={`box-border text-2xl sm:text-3xl align-middle inline-block w-1/3 h-36 sm:h-28 ${
              factory == 2 && "selectList"
            }`}
          >
            <button className="w-full h-full" onClick={() => factoryHandler(2)}>
              <Link
                to="/map/joil"
                className=" w-full h-full flex flex-wrap content-center justify-center"
              >
                (주)조일
              </Link>
            </button>
          </li>
        </ul>
        {factory == 0 && <BONSA />}
        {factory == 1 && <YEONAM />}
        {factory == 2 && <BASE />}
      </div>
      <FOOTER />
    </main>
  );
};
export default MAP;
