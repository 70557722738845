import React from "react";
import "../../css/map.css";
export const EN_BONSA = () => {
  return (
    <div className="pt-24 px-4 max-w-screen-lg m-auto">
      <h4 className="text-4xl sm:text-5xl font-bold text-center">
        Joil Co., Ltd. HQ
      </h4>
      <div className="w-36 h-1 bg-gray-500 m-auto mt-12 mb-24"></div>
      <div className="max-w-screen-lg m-auto pb-24">
        <div className="mapRatio relative">
          <iframe
            className="w-full h-full absolute left-0 top-0"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d684.281845926747!2d129.36846003252225!3d35.56299993566352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3567cc5c468b055d%3A0xc4e340ab5bcae26f!2z7Jq47IKw6rSR7Jet7IucIOu2geq1rCDtmqjrrLjrj5kg7Zqo7ISx66GcIDQz!5e0!3m2!1sko!2skr!4v1636356208875!5m2!1sko!2skr"
          ></iframe>
        </div>
      </div>
      <table className="info border-t-2 border-b-2 w-full border-gray-700">
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Date established
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">1997. 01. 25.</td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Business type
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">
            Car body part production
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Factory size
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">
            Site area 142,300 sqft / Building area 121,000 sqft
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Key items
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5 uppercase">
            300 items including FEM/ASSIST HDL MTG / A/S COVER
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Main customers
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">
            Hyundai Motor Company, Kia Motors, Hyundai Mobis, etc.
          </td>
        </tr>
        <tr className="border-b-2">
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Address
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">
            43, Hyoseong-ro, Buk-gu, Ulsan (Hyomun-dong)
          </td>
        </tr>
        <tr>
          <th className="font-bold text-2xl sm:text-3xl w-2/5 leading-loose sm:leading-loose">
            Contact No.
          </th>
          <td className="font text-2xl sm:text-3xl w-3/5">052-280-0900</td>
        </tr>
      </table>
    </div>
  );
};
