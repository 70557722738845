export type FadeDirection = "bottom-left" | "bottom-right" | "down" | "left" | "right" | "top-left" | "top-right" | "up";

export interface IHistoryProps {
  direction: FadeDirection;
  years: string;
  textLogo: string;
  textLogo_en: string;
  text: string;
  text_en: string;
}

export const listHistory: IHistoryProps[] = [
  {
    direction: "left",
    years: "2021",
    textLogo: "",
    textLogo_en: "",
    text: `테크노 산업단지 착공 예정`,
    text_en: `Pending construction in Techno Industrial Complex`,
  },
  {
    direction: "right",
    years: "2018",
    textLogo: "10",
    textLogo_en: "Oct",
    text: `울산 스타기업 선정`,
    text_en: `Selected as Ulsan star company`,
  },
  {
    direction: "left",
    years: "2014",
    textLogo: "",
    textLogo_en: "",
    text: `600톤 프레스 라인 증설
    400톤 트랜스퍼 자동화 라인`,
    text_en: `Extension of 600-ton press line 400-ton transfer automation line`,
  },
  {
    direction: "right",
    years: "2007",
    textLogo: "",
    textLogo_en: "",
    text: `조일공업(주) 기술연구소 설립 로보트자동화 라인 (300톤, 250톤) 금형 자동화 적재 
    창고`,
    text_en: `Established Joil Co., Ltd. technology lab robot automation line (300 ton, 250 ton), mold automation loading storage`,
  },
  {
    direction: "left",
    years: "2006",
    textLogo: "01",
    textLogo_en: "Jan",
    text: `경영혁신형 중소기업 인증`,
    text_en: `Inno-biz certification`,
  },
  {
    direction: "right",
    years: "2005",
    textLogo: "",
    textLogo_en: "",
    text: `부품 공급망관리 시스템(SCM) 구축
    생산 정보화 우수기업 선정`,
    text_en: `Established part supply chain management (SCM) system selected as outstanding production information system company`,
  },
  {
    direction: "left",
    years: "2004",
    textLogo: "",
    textLogo_en: "",
    text: `생산 정보화 시스템 (POP) 구축`,
    text_en: `Established point of purchase (POP) system`,
  },
  {
    direction: "right",
    years: "2002",
    textLogo: "12",
    textLogo_en: "Dec",
    text: `경영정보 시스템(ERP) 구축`,
    text_en: `Established enterprise resource planning (ERP) system`,
  },
  {
    direction: "left",
    years: "1997",
    textLogo: "01",
    textLogo_en: "Jan",
    text: `조일공업㈜ 설립`,
    text_en: `Founded Joil Co., Ltd.`,
  },
];
