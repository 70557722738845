import "../layout.css";
import logo from "../../assets/icon/logo.png";
import menu from "../../assets/icon/Hamburger_icon.svg";
import * as func from "../lay_func";
import { useReactiveVar } from "@apollo/client";
import { divide, url } from "../../apollo";
import { useState } from "react";
import close from "../../assets/icon/xicon.png";
import { MobileMenu } from "./mobileMenu";

export const HEADER = () => {
  const pathname = useReactiveVar(url);
  const [open, setopen] = useState<boolean>(false);
  const [line, setline] = useState<number>(9);
  const [mopen, setmopen] = useState<boolean>(false);
  const [mmenu, setmmenu] = useState<number>(9);
  const openHandler = (index: number) => {
    setopen(() => {
      return true;
    });
    setline(() => {
      return index;
    });
  };
  const closeHandler = () => {
    setopen(() => {
      return false;
    });
    setline(() => {
      return 9;
    });
  };
  const mopenHandler = () => {
    setmopen(() => {
      if (mopen) {
        return false;
      }
      return true;
    });
  };
  const mmenuHandler = (index: number) => {
    setmmenu(() => {
      if (mmenu == index) {
        return 9;
      }
      return index;
    });
  };
  return (
    <div className="headerWrap fixed top-0 left-0 w-full z-20">
      <header id="header" className={`header-main ${open && "open"}`} onMouseLeave={closeHandler}>
        <div className="setlang max-w-screen-xl mx-auto h-10 hidden lg:flex flex-wrap content-end justify-end ">
          <a href="/" className="text-center text-xl font-bold text-white mx-5 langSelected">
            KOR
          </a>
          <a href="/en" className="text-center text-xl font-bold text-white mx-5 ">
            ENG
          </a>
        </div>
        <div className="innerheader max-w-screen-xl mx-auto h-32 flex flex-wrap lg:justify-between justify-center px-3">
          <div className="submenuBack lg:block hidden shadow-lg"></div>
          <h1 className="w-40 flex flex-wrap content-center h-full lg:h-24">
            <a href="/">
              <img src={logo} alt="joil공업 로고" className="w-full" />
            </a>
          </h1>
          <div className="h-full menu lg:hidden px-12 absolute right-0 flex flex-wrap content-center">
            <button onClick={mopenHandler}>
              <img src={menu} alt="조일공업 메뉴열기버튼" className="reverse-color" />
            </button>
          </div>
          <nav id="GNB" className="text-2xl w-7/12  webnav lg:inline-block hidden">
            <ul className="inline-block w-full">
              {func.menu.map((obj) => (
                <li className="inline-block relative w-1/5" key={`${obj.main}mainmenu`}>
                  <a
                    href={obj.mainLink}
                    className={`mainTab h-32 flex-wrap flex content-center justify-center text-white  ${(divide(pathname).mainValue == obj.main && "mainTabLine") || (line == obj.index && "mainTabLine")}`}
                    onFocus={() => openHandler(obj.index)}
                    onMouseEnter={() => openHandler(obj.index)}
                  >
                    {obj.main}
                  </a>
                  {func.menu[obj.index].sub.length > 0 && (
                    <ul className={`submenu absolute pt-5 w-full text-center ${line == obj.index && "subtab"}`} onMouseEnter={() => openHandler(obj.index)}>
                      {func.menu[obj.index].sub.map((obj) => (
                        <li key={obj.menus}>
                          <a href={obj.links} className="block hover:font-bold hover:text-main1 py-5 hover:bg-gray-300 text-black">
                            {obj.menus}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>

          <div className={`${mopen ? "cutton-open" : "hidden"}`}></div>
          <nav id="MNB" className={`text-2xl w-full max-w-lg bg-main1 lg:hidden min-h-screen fixed mobilenav ${mopen ? "nav-open" : "nav-hide"}`}>
            <ul className="h-screen overflow-scroll">
              <li className="border-b border-main2 h-32 relative flex flex-wrap content-center justify-center">
                <div>
                  <img src={logo} alt="조일공업로고" width="100" />
                </div>
                <div className="h-full menu px-12 absolute right-0 flex flex-wrap content-center">
                  <button onClick={mopenHandler}>
                    <img width="25" src={close} alt="조일공업 메뉴닫기 버튼" className="reverse-color" />
                  </button>
                </div>
              </li>
              <li className="h-20 flex flex-wrap px-10 justify-end content-center setlang border-b border-main2">
                <a href="/" className="text-xl langSelected px-6">
                  KOR
                </a>
                <a href="/en" className="text-xl px-6">
                  ENG
                </a>
              </li>
              {func.menu.map((obj) => (
                <li className="block relative w-full mobilelist" key={obj.index}>
                  {func.menu[obj.index].sub.length > 0 ? (
                    <MobileMenu value={obj} />
                  ) : (
                    <a href={obj.mainLink} className="mainTab h-32 flex-wrap flex content-center justify-center hover:bg-gray-800 focus:bg-gray-800">
                      {obj.main}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
};
