import React from "react";
import cer1 from "../assets/images/intro/certification1.jpg";
import cer2 from "../assets/images/intro/certification2.jpg";
import cer3 from "../assets/images/intro/certification3.jpg";
import cer4 from "../assets/images/intro/certification4.jpg";
import cer5 from "../assets/images/intro/certification5.jpg";
import cer6 from "../assets/images/intro/certification6.jpg";
import cer7 from "../assets/images/intro/certification7.jpg";
import cer8 from "../assets/images/intro/certification8.jpg";
import cer9 from "../assets/images/intro/certification9.jpg";

export interface ICertificationProps {
  name: string;
  name_en: string;
  url: string;
}

export const listCertification: ICertificationProps[] = [
  { name: "이노비즈 인증서", name_en: "Inno-biz certificate", url: cer1 },
  { name: "글로벌 선도기업 인증서", name_en: "Global leading company certificate", url: cer2 },
  { name: "기업부설연구소 인증서", name_en: "Company affiliated lab certificate", url: cer3 },
  { name: "ISO 45001 인증서", name_en: "ISO 45001 certificate", url: cer4 },
  { name: "중소기업 확인서", name_en: "SME confirmation", url: cer5 },
  { name: "울산스타기업 지정서", name_en: "Ulsan star company selection certificate", url: cer6 },
  { name: "뿌리기업 확인서", name_en: "Root company confirmation certificate", url: cer7 },
  { name: "ISO 9001 인증서", name_en: "ISO 9001 certificate", url: cer8 },
  { name: "IATF 16949 인증서", name_en: "IATF 16949 certificate", url: cer9 },
];
