import React from "react";
import { FOOTER } from "../../../layout/eng/footer";
import { SUBHEADER } from "../../../layout/eng/subheader";
import { EN_Organization } from "../../../parts/eng/intro/organization";
import { Helmet } from "react-helmet";
export const EN_ORGANIZATION = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="intro">
        <EN_Organization />
      </div>
      <FOOTER />
    </main>
  );
};
