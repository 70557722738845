import React from "react";
import { FOOTER } from "../../../layout/kor/footer";
import { SUBHEADER } from "../../../layout/kor/subheader";
import performance from "../../../assets/images/esg/performance.png";

export const PERFORMANCE = () => {
  return (
    <main id="content">
      <SUBHEADER />
      <div id="esg" className="max-w-screen-lg w-full px-8 pb-32 sm:pb-64 pt-48 m-auto">
        <img src={performance} className="w-full"/>
      </div>
      <FOOTER />
    </main>
  );
};
