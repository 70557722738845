import comment from "../assets/images/subheader/Visual_joil_16.jpg";
import ideology from "../assets/images/subheader/Visual_joil_23.jpg";
import philosophy from "../assets/images/subheader/Visual_joil_08.jpg";
import history from "../assets/images/subheader/Visual_joil_01.jpg";
import certification from "../assets/images/subheader/Visual_joil_02.jpg";
import organization from "../assets/images/subheader/Visual_joil_09.jpg";
import management from "../assets/images/subheader/Visual_joil_17.jpg";
import line from "../assets/images/subheader/Visual_joil_02.jpg";
import products from "../assets/images/subheader/Visual_joil_24.jpg";
import facility from "../assets/images/subheader/Visual_joil_07.jpg";
import talent from "../assets/images/subheader/Visual_joil_14.jpg";
import rule from "../assets/images/subheader/Visual_joil_10.jpg";
import work from "../assets/images/subheader/Visual_joil_20.jpg";
import benefit from "../assets/images/subheader/Visual_joil_12.jpg";
import announce from "../assets/images/subheader/Visual_joil_13.jpg";
import base from "../assets/images/subheader/Visual_joil_05.jpg";
import yeonam from "../assets/images/subheader/Visual_joil_05.jpg";
import joil from "../assets/images/subheader/Visual_joil_05.jpg";

// HEADER
export const menu = [
  {
    main: "회사소개",
    sub: [
      { menus: "인사말", subback: comment, links: "/intro/comment" },
      { menus: "경영이념", subback: ideology, links: "/intro/ideology" },
      { menus: "경영철학", subback: philosophy, links: "/intro/philosophy" },
      { menus: "회사연혁", subback: history, links: "/intro/history" },
      {
        menus: "인증현황",
        subback: certification,
        links: "/intro/certification",
      },
      { menus: "조직도", subback: organization, links: "/intro/organization" },
    ],
    mainLink: "/intro/comment",
    index: 0,
  },
  {
    main: "사업소개",
    sub: [
      { menus: "경영활동", subback: management, links: "/business/management" },
      { menus: "생산라인", subback: line, links: "/business/line" },
      { menus: "생산품목", subback: products, links: "/business/products" },
      { menus: "설비현황", subback: facility, links: "/business/facility" },
    ],
    mainLink: "/business/management",
    index: 1,
  },
  {
    main: "ESG 경영",
    sub: [
      { menus: "경영위원회", subback: management, links: "/esg/committe" },
      { menus: "공정거래지침", subback: line, links: "/esg/guidelines" },
      { menus: "윤리경영성과", subback: products, links: "/esg/performance" },
    ],
    mainLink: "/esg/committe",
    index: 2,
  },
  {
    main: "인재채용",
    sub: [
      { menus: "인재상", subback: talent, links: "/recruit/talent" },
      { menus: "인사정책", subback: rule, links: "/recruit/rule" },
      { menus: "직무소개", subback: work, links: "/recruit/work" },
      {
        menus: "보상정책",
        subback: benefit,
        links: "/recruit/benefit",
      },
      { menus: "채용안내", subback: announce, links: "/recruit/announce" },
    ],
    mainLink: "/recruit/talent",
    index: 3,
  },
  {
    main: "오시는 길",
    sub: [
      { menus: "조일공업(주) 본사", subback: base, links: "/map/base" },
      { menus: "연암공장", subback: yeonam, links: "/map/yeonam" },
      { menus: "(주)조일", subback: joil, links: "/map/joil" },
    ],
    index: 4,
    mainLink: "/map/base",
  },
];

export const en_menu = [
  {
    main: "Introduction",
    sub: [
      { menus: "Greetings", subback: comment, links: "/en/intro/comment" },
      { menus: "Ideology", subback: ideology, links: "/en/intro/ideology" },
      {
        menus: "Philosophy",
        subback: philosophy,
        links: "/en/intro/philosophy",
      },
      { menus: "History", subback: history, links: "/en/intro/history" },
      {
        menus: "Vertification",
        subback: certification,
        links: "/en/intro/certification",
      },
      {
        menus: "Organization",
        subback: organization,
        links: "/en/intro/organization",
      },
    ],
    mainLink: "/en/intro/comment",
    index: 0,
  },
  {
    main: "Business",
    sub: [
      {
        menus: "Management",
        subback: management,
        links: "/en/business/management",
      },
      { menus: "Prod-Line", subback: line, links: "/en/business/line" },
      { menus: "Prod-Item", subback: products, links: "/en/business/products" },
      { menus: "Facility", subback: facility, links: "/en/business/facility" },
    ],
    mainLink: "/en/business/management",
    index: 1,
  },
  {
    main: "ESG",
    sub: [
      { menus: "Committee", subback: management, links: "/en/esg/committe" },
      { menus: "Guidelines", subback: line, links: "/en/esg/guidelines" },
      { menus: "Performance", subback: products, links: "/en/esg/performance" },
    ],
    mainLink: "/en/esg/committe",
    index: 2,
  },
  {
    main: "Careers",
    sub: [
      {
        menus: "Talent",
        subback: talent,
        links: "/en/recruit/talent",
      },
      { menus: "HR policy", subback: rule, links: "/en/recruit/rule" },
      {
        menus: "Job introduction",
        subback: work,
        links: "/en/recruit/work",
      },
      {
        menus: "Benefits",
        subback: benefit,
        links: "/en/recruit/benefit",
      },
      { menus: "recruit", subback: announce, links: "/en/recruit/announce" },
    ],
    mainLink: "/en/recruit/talent",
    index: 3,
  },
  {
    main: "Directions",
    sub: [
      { menus: "Joil Co., Ltd. HQ", subback: base, links: "/en/map/base" },
      { menus: "Yeonam Factory", subback: yeonam, links: "/en/map/yeonam" },
      { menus: "Joil Co., Ltd.", subback: joil, links: "/en/map/joil" },
    ],
    index: 4,
    mainLink: "/en/map/base",
  },
];
